export const supportedLanguages = [
  {
    value: "English",
    key: "en",
  },
  /*{
    value: "Afrikaans",
    key: "af",
  },
  {
    value: "Albanian",
    key: "sq",
  },
  {
    value: "Amharic",
    key: "am",
  },*/
  {
    value: "Arabic",
    key: "ar",
  },
  /*{
    value: "Armenian",
    key: "hy",
  },
  {
    value: "Azerbaijani",
    key: "az",
  },
  {
    value: "Basque",
    key: "eu",
  },
  {
    value: "Belarusian",
    key: "be",
  },
  {
    value: "Bengali",
    key: "bn",
  },
  {
    value: "Bosnian",
    key: "bs",
  },
  {
    value: "Bulgarian",
    key: "bg",
  },
  {
    value: "Catalan",
    key: "ca",
  },
  {
    value: "Cebuano",
    key: "ceb",
  },
  {
    value: "Corsican",
    key: "co",
  },
  {
    value: "Croatian",
    key: "hr",
  },
  {
    value: "Czech",
    key: "cs",
  },
  {
    value: "Danish",
    key: "da",
  },
  {
    value: "Dutch",
    key: "nl",
  },
  {
    value: "Esperanto",
    key: "eo",
  },
  {
    value: "Estonian",
    key: "et",
  },
  {
    value: "Filipino (Tagalog)",
    key: "fil",
  },
  {
    value: "Finnish",
    key: "fi",
  },
  {
    value: "French",
    key: "fr",
  },
  {
    value: "Frisian",
    key: "fy",
  },
  {
    value: "Galician",
    key: "gl",
  },
  {
    value: "Georgian",
    key: "ka",
  },
  {
    value: "German",
    key: "de",
  },
  {
    value: "Greek",
    key: "el",
  },
  {
    value: "Gujarati",
    key: "gu",
  },
  {
    value: "Haitian Creole",
    key: "ht",
  },
  {
    value: "Hausa",
    key: "ha",
  },
  {
    value: "Hawaiian",
    key: "haw",
  },
  {
    value: "Hebrew",
    key: "he",
  },
  {
    value: "Hindi",
    key: "hi",
  },
  {
    value: "Hmong",
    key: "hmn",
  },
  {
    value: "Hungarian",
    key: "hu",
  },
  {
    value: "Icelandic",
    key: "is",
  },
  {
    value: "Igbo",
    key: "ig",
  },
  {
    value: "Indonesian",
    key: "id",
  },
  {
    value: "Irish",
    key: "ga",
  },
  {
    value: "Italian",
    key: "it",
  },
  {
    value: "Japanese",
    key: "ja",
  },
  {
    value: "Kannada",
    key: "kn",
  },
  {
    value: "Kazakh",
    key: "kk",
  },
  {
    value: "Khmer",
    key: "km",
  },
  {
    value: "Kinyarwanda",
    key: "rw",
  },
  {
    value: "Korean",
    key: "ko",
  },
  {
    value: "Kurdish",
    key: "ku",
  },
  {
    value: "Kyrgyz",
    key: "ky",
  },
  {
    value: "Lao",
    key: "lo",
  },
  {
    value: "Latin",
    key: "la",
  },
  {
    value: "Latvian",
    key: "lv",
  },
  {
    value: "Lithuanian",
    key: "lt",
  },
  {
    value: "Luxembourgish",
    key: "lb",
  },
  {
    value: "Macedonian",
    key: "mk",
  },
  {
    value: "Malagasy",
    key: "mg",
  },
  {
    value: "Malay",
    key: "ms",
  },
  {
    value: "Malayalam",
    key: "ml",
  },
  {
    value: "Maltese",
    key: "mt",
  },
  {
    value: "Maori",
    key: "mi",
  },
  {
    value: "Marathi",
    key: "mr",
  },
  {
    value: "Mongolian",
    key: "mn",
  },
  {
    value: "Myanmar (Burmese)",
    key: "my",
  },
  {
    value: "Nepali",
    key: "ne",
  },
  {
    value: "Norwegian",
    key: "no",
  },
  {
    value: "Nyanja (Chichewa)",
    key: "ny",
  },
  {
    value: "Odia (Oriya)",
    key: "or",
  },
  {
    value: "Pashto",
    key: "ps",
  },
  {
    value: "Persian",
    key: "fa",
  },
  {
    value: "Polish",
    key: "pl",
  },
  {
    value: "Portuguese (Portugal, Brazil)",
    key: "pt",
  },
  {
    value: "Punjabi",
    key: "pa",
  },
  {
    value: "Romanian",
    key: "ro",
  },
  {
    value: "Russian",
    key: "ru",
  },
  {
    value: "Samoan",
    key: "sm",
  },
  {
    value: "Scots Gaelic",
    key: "gd",
  },
  {
    value: "Serbian",
    key: "sr",
  },
  {
    value: "Sesotho",
    key: "st",
  },
  {
    value: "Shona",
    key: "sn",
  },
  {
    value: "Sindhi",
    key: "sd",
  },
  {
    value: "Sinhala (Sinhalese)",
    key: "si",
  },
  {
    value: "Slovak",
    key: "sk",
  },
  {
    value: "Slovenian",
    key: "sl",
  },
  {
    value: "Somali",
    key: "so",
  },
  {
    value: "Spanish",
    key: "es",
  },
  {
    value: "Sundanese",
    key: "su",
  },
  {
    value: "Swahili",
    key: "sw",
  },
  {
    value: "Swedish",
    key: "sv",
  },
  {
    value: "Tagalog (Filipino)",
    key: "tl",
  },
  {
    value: "Tajik",
    key: "tg",
  },
  {
    value: "Tamil",
    key: "ta",
  },
  {
    value: "Tatar",
    key: "tt",
  },
  {
    value: "Telugu",
    key: "te",
  },
  {
    value: "Thai",
    key: "th",
  },
  {
    value: "Turkish",
    key: "tr",
  },
  {
    value: "Turkmen",
    key: "tk",
  },
  {
    value: "Ukrainian",
    key: "uk",
  },
  {
    value: "Urdu",
    key: "ur",
  },
  {
    value: "Uyghur",
    key: "ug",
  },
  {
    value: "Uzbek",
    key: "uz",
  },
  {
    value: "Vietnamese",
    key: "vi",
  },
  {
    value: "Welsh",
    key: "cy",
  },
  {
    value: "Xhosa",
    key: "xh",
  },
  {
    value: "Yiddish",
    key: "yi",
  },
  {
    value: "Yoruba",
    key: "yo",
  },
  {
    value: "Zulu",
    key: "zu",
  },*/
];
