import warningImg from "../../assets/images/warning.svg";
import React from "react";
import { useTranslation } from "react-i18next";

const Invalid = () => {
  const { t } = useTranslation();
  return (
    <div className="redeemVoucher">
      <div className="code-border">
        <img loading="lazy" src={warningImg} alt="rewardImg" />
        <h6>{[t("THIS_VOUCHER_HAS")]}</h6>
      </div>
    </div>
  );
};

export default Invalid;
