import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logoFooter.svg";

function Footer({ title }) {
  const { t } = useTranslation();

  return (
    <div className="Logo_bottom">
      <ul>
        <li>
          {" "}
          <span>{title === "home" ? "" : [t("BY")]} </span>{" "}
          <img src={logo} alt="Trustree" loading="lazy" />
        </li>
      </ul>
    </div>
  );
}

export default Footer;
