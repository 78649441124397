import { useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "./../../../components/landing/NavBar";
import Footer from "./../../../components/landing/Footer";
import ScrollingFeatureText from "./../../../components/landing/ScrollingFeatureText";
import FeatureText from "./../../../components/landing/FeatureText";
import LeaveCard from "./../../../components/landing/LeaveCard";
import CWCaseStudyImage from "./../../../assets/images/landing/cwcasestudy.svg";
import ConnectIcon from "./../../../assets/images/landing/connectIcon.svg";
import AnalyzeIcon from "./../../../assets/images/landing/analyzeIcon.svg";
import ResolveIcon from "./../../../assets/images/landing/resolveIcon.svg";
import GrowIcon from "./../../../assets/images/landing/growIcon.svg";
import LeftArrow from "./../../../assets/images/landing/leftarrow.svg";
import RightArrow from "./../../../assets/images/landing/rightarrow.svg";
import HeroArrow from "./../../../assets/images/landing/heroarrow.svg";
import LeaveCardLeave from "./../../../assets/images/landing/leavecardleave.svg";
import Message from "./../../../assets/images/landing/message.svg";
import Survey from "./../../../assets/images/landing/survey.svg";
import Google from "./../../../assets/images/landing/google.svg";
import Profile from "./../../../assets/images/landing/profile.svg";
import Menu from "./../../../assets/images/landing/menu.svg";
import Analytics from "./../../../assets/images/landing/analytics.svg";
import HeroChatPopUp from "./../../../assets/images/landing/herochatpopup.svg";
import CheckMark from "./../../../assets/images/landing/checkmark.svg";
import { useState } from "react";
import "./HomePage.css";
import TestimonialCard from "./../../../components/landing/TestimonialCard";
import PlanCard from "./../../../components/landing/PlanCard";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PopupModal } from "react-calendly";

import Carousel from "react-bootstrap/Carousel";

import { useMainContextDataLayerValue } from "../../../context/MainContextDataLayer";
import LandingDataReducerActionTypes from "../../../context/MainContextDataTypes";

import Slider from "react-slick";
import { Link } from "react-router-dom";

const HomePage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, reset } = useForm();
  const [isContactUsFormSubmitting, setIsContactUsFormSubmitting] =
    useState(false);

  const [isLeavesViewAllButtonClicked, setIsLeavesViewAllButtonClicked] =
    useState(false);

  const [{ isCalendlyOpen, plansMonthlyAnnuallyToggle }, dispatch] =
    useMainContextDataLayerValue();

  const [trustreeLeavesSliderSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <img className="" src={RightArrow}></img>,
    prevArrow: <img className="" src={LeftArrow}></img>,
  });
  const onContactUsFormSubmit = (data) => {
    console.log(data);
    setIsContactUsFormSubmitting(true);
    var formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch("https://inframodern.com/sendemail.php", requestOptions)
      // .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        toast.success(t("Your message was sent successfully"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        reset({
          source: "TrustreeLandingPage",
          name: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
        });
        setIsContactUsFormSubmitting(false);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(t("Error happend please try again later"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsContactUsFormSubmitting(false);
      });
  };

  return (
    <div className="bg-[#FFFFFF]">
      <NavBar></NavBar>
      <section id="hero" className="w-full h-full">
        <div class="w-full h-full">
          <div className="w-full h-full flex flex-col desktop:!pt-52 desktop:!pb-20 mobile:!pt-36 mobile:!pb-48">
            <div class="w-full flex mobile:flex-col-reverse desktop:flex-row h-full">
              <div class="flex flex-col text-center items-center justify-center h-full w-full">
                <div class="relative font-semibold desktop:text-6xl desktop:leading-[76px] mobile:text-2xl mobile:leading-[45px] w-full text-[#181818]">
                  <span className="font-Trap whitespace-nowrap">
                    {t("Shaping ")}
                  </span>
                  <span className="font-Trap bg-gradient-to-r from-[#2194F3] to-[#5EFF6F] inline-block text-transparent bg-clip-text">
                    {t("Experiences, ")}
                  </span>
                  <span className="font-Trap whitespace-nowrap">
                    {t(" Amplifying ")}
                  </span>
                  <span className="font-Trap bg-gradient-to-r from-[#2194F3] to-[#5EFF6F] inline-block text-transparent bg-clip-text">
                    {t("Voices.")}
                  </span>
                </div>
                <h1 class="font-SpaceGrotesk font-medium desktop:text-base desktop:w-[577px] mobile:text-xs mobile:!px-10 text-[#181818] w-full !mt-4">
                  {t(
                    "Trust is the foundation of any relationship. In business and life. It needs to be earned. It needs growth and can't be taken for granted. Trust is a result of care and responsibility. Trustree helps you grow and maintain healthy customer relationships. Trustree isn't a solution. It is a foundation."
                  )}
                </h1>
              </div>
            </div>
            <div className="!mt-24 whitespace-pre-line text-[#181818] font-SpaceGrotesk text-center font-semibold desktop:text-4xl mobile:text-base">
              <span
                style={{
                  borderStyle: "solid",
                  borderWidth: "0 0 2px 0",
                  borderImage: "linear-gradient(45deg, #5EFF6F, #2194F3) 1",
                }}
              >
                {t("Trusted By")}
              </span>
            </div>
            <div className="!mt-8 flex desktop:flex-row mobile:flex-col justify-center items-center desktop:gap-20 mobile:gap-10">
              <div className="logoMarqueeSection !py-8 desktop:max-w-7xl mobile:max-w-full">
                <div className="container relative" id="logoMarqueeSection">
                  <div className="absolute inset-y-0 left-2 w-10 bg-gradient-to-r from-[#FFFFFF] to-transparent z-10"></div>
                  <div className="absolute inset-y-0 right-2 w-10 bg-gradient-to-l from-[#FFFFFF] to-transparent z-10"></div>
                  <div className="default-content-container flex items-center">
                    <div className="default-content-container-inner marquee-wrapper relative overflow-hidden">
                      <div
                        className="flex justify-center items-center gap-20 marquee-scroll-left"
                        style={{ animationDuration: "40s" }}
                      >
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/cw-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/elsewedy-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/Happy_Joe's_logo 1.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/shakour.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/arkanum.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/volga.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/barbar.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/karaktap.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/3diner.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/maine.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/vinnyspizza.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/cw-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/elsewedy-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/Happy_Joe's_logo 1.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/shakour.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/arkanum.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/volga.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/barbar.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/karaktap.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/3diner.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/maine.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/vinnyspizza.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/cw-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/elsewedy-logo.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/Happy_Joe's_logo 1.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/shakour.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/arkanum.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/volga.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/barbar.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/karaktap.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/3diner.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/maine.png")}
                        ></img>
                        <img
                          className="rounded-none"
                          src={require("./../../../assets/images/landing/vinnyspizza.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="bg-[#FFFFFF] h-14">
        <div className="w-full h-full">
          <div className="h-14 logoMarqueeSection !py-2 bg-gradient-to-r from-[#5EFF6F] to-[#2194F3]">
            <div className="container" id="logoMarqueeSection">
              <div className="default-content-container flex items-center">
                <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
                  <div
                    className="flex justify-center items-center gap-8 marquee"
                    style={{ animationDuration: "30s" }}
                  >
                    <ScrollingFeatureText
                      text={t("Instantly see customer feedback")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Solve customer complaints in seconds")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Respond to customer feedback with speed")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t(
                        "Incentivize staff to continuously uphold high standards"
                      )}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t(
                        "Show customers you care; listening to your customers is a form of love"
                      )}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Generate 10X more positive google reviews")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Get data to improve internal operations")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Have an eye on operations without being there")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Turn all your customers into mystery shoppers")}
                    ></ScrollingFeatureText>
                    <ScrollingFeatureText
                      text={t("Easily track staff service performance")}
                    ></ScrollingFeatureText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="leaves" className="">
        <div class="w-full h-full">
          <div className="!py-24 desktop:!px-10 mobile:!px-2 ">
            <h1 class="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-4xl mobile:text-3xl desktop:!pb-12 mobile:!pb-6">
              <span
                style={{
                  borderStyle: "solid",
                  borderWidth: "0 0 2px 0",
                  borderImage: "linear-gradient(45deg, #2194F3, #5EFF6F) 1",
                }}
              >
                {t("Trustree’s Leaves")}
              </span>
            </h1>

            <Carousel
              className="!pt-2 !px-20"
              controls={true}
              touch={true}
              indicators={false}
              pause={"hover"}
              interval={3000}
              nextIcon={<img className="h-20" src={RightArrow}></img>}
              prevIcon={<img className="h-20" src={LeftArrow}></img>}
            >
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("Analysis Dashboard")}
                      subtitle={t(
                        "View all your customer's likes and dislikes in one dashboard, organized and analyzed."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Feedbacks")}
                      subtitle={t(
                        "View all your customers' words of feedback across your different locations and reply to each one of them with a click of a button."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("One QR Code")}
                      subtitle={t(
                        "With one simple QR code you can do it all, a menu and a feedback survey."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("Google Integration")}
                      subtitle={t(
                        "With an already built-in google integration you can manage your location(s) on google from Trustree."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Brand Rating")}
                      subtitle={t(
                        "See how your different brands rate against each other."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Location Rating")}
                      subtitle={t(
                        "Easily compare and see how your different locations perform and rate differently."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("Rewards")}
                      subtitle={t(
                        "Show your customers you care by giving them rewards for their feedback."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Notifications")}
                      subtitle={t(
                        "Get automated instant notifications once someone gives you a rating below a certain number you decide."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Multiple Experience Types")}
                      subtitle={t(
                        "See how your customers react differently to the different experiences you offer."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("Custom Survey")}
                      subtitle={t(
                        "Add or Remove questions instantly to your survey with a click of a button."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Custom Design")}
                      subtitle={"Create a survey matching your brand identity."}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("QR Codes Stickers")}
                      subtitle={t(
                        "Download pre-designed QR code stickers tailored for your brand."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("QR Code Lists")}
                      subtitle={t(
                        "Display your catalogue or menu of products and services through a QR Code."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Multiple Brands")}
                      subtitle={t(
                        "Manage all your brands from one account and one dashboard."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Products Ratings")}
                      subtitle={t(
                        "Understand how each of your products influences your customer's experiences."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex desktop:flex-row mobile:flex-col justify-center items-center gap-10">
                  <div>
                    <LeaveCard
                      title={t("Staff Rating")}
                      subtitle={t(
                        "Let your customers rate your staff members and see which of your staff members consistently offers the best customer experience."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Customer Profile")}
                      subtitle={t(
                        "Get a full analysis on each one of your customers to continuously improve your relationship."
                      )}
                    ></LeaveCard>
                  </div>
                  <div>
                    <LeaveCard
                      title={t("Customer Connect")}
                      subtitle={t(
                        "Connect with your customers by responding to their feedback through a tailored inbox built for customer engagement."
                      )}
                    ></LeaveCard>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="h-14 !py-2 bg-gradient-to-r from-[#2194F3] to-[#5EFF6F]"></div>
        </div>
      </section>
      <section id="cwcasestudy" className="bg-[#181818]">
        <div class="w-full h-full">
          <div className="w-full h-full flex flex-col desktop:!pt-52 desktop:!pb-20 mobile:!pt-36 mobile:!pb-48">
            <div class="w-full flex mobile:flex-col-reverse desktop:flex-row desktop:px-10 desktop:!pl-32 mobile:!pl-9 mobile:!pr-9 h-full">
              <div class="flex flex-col justify-center h-full desktop:w-1/2">
                <div class="relative font-semibold desktop:text-6xl desktop:w-[600px] desktop:leading-[76px] mobile:text-lg mobile:leading-[45px] w-full text-[#FFFFFF]">
                  <div className="font-Trap ">
                    {t("How Chickin Worx, Worxed it !")}
                  </div>
                </div>
                <h1 class="font-SpaceGrotesk font-medium desktop:text-base desktop:w-[600px] text-[#FFFFFF] mobile:text-sm w-full !mt-4">
                  {t(
                    "Chickin Worx was able to use Trustree to become one of the most customer centric businesses in the Middle East. They are currently the most highly rated restaurant in Egypt on Google. The management at Chickin Worx really understands the importance of putting the customer at the center of the main stage. Chickin Worx pin points Trustree as a main tool and instrument that helped them raise their customer experience standards tremendously."
                  )}
                </h1>
                <div className="desktop:!py-8 mobile:!py-8">
                  <Link
                    to="/blog/chickin-worx-case-study"
                    className="rounded-[70px] text-lg !px-5 !py-3 font-SpaceGrotesk font-medium text-[#181818] bg-[#5EFF6F] hover:bg-gray-500"
                  >
                    {t("Read More")}
                  </Link>
                </div>
              </div>
              <div class="h-full mobile:!pb-8 desktop:w-1/2">
                <iframe
                  className="w-full h-[400px]"
                  src="https://www.youtube.com/embed/XyBJfQnahIA?si=wmH7N6NFbylv25kc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="corevalues" className="">
        <div class="w-full h-full">
          <div className="!py-24 desktop:!px-20 mobile:!px-10 ">
            <h1 class="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-4xl mobile:text-3xl desktop:!pb-36 mobile:!pb-6">
              <span
                style={{
                  borderStyle: "solid",
                  borderWidth: "0 0 2px 0",
                  borderImage: "linear-gradient(45deg, #2194F3, #5EFF6F) 1",
                }}
              >
                {t("Core Values")}
              </span>
            </h1>
            <div className="flex desktop:flex-row mobile:flex-col desktop:justify-evenly mobile:justify-center mobile:items-center gap-12 !pt-10 !pb-24">
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={ConnectIcon}></img>
                </div>
                <div className="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-3xl mobile:text-3xl">
                  {t("Connect")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={AnalyzeIcon}></img>
                </div>
                <div className="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-3xl mobile:text-3xl">
                  {t("Analyze")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={ResolveIcon}></img>
                </div>
                <div className="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-3xl mobile:text-3xl">
                  {t("Resolve")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={GrowIcon}></img>
                </div>
                <div className="font-SpaceGrotesk text-center font-semibold text-[#181818] desktop:text-3xl mobile:text-3xl">
                  {t("Grow")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="helpingbusiness" className="bg-[#181818]">
        <div class="w-full h-full">
          <div className="w-full h-full flex flex-col desktop:!pt-20 desktop:!pb-20 mobile:!pt-36 mobile:!pb-48">
            <h1 class="text-center font-SpaceGrotesk font-semibold text-[#FFFFFF] desktop:text-6xl mobile:text-2xl desktop:!pb-10 mobile:!pb-4">
              <span className="font-Trap whitespace-nowrap">
                {t("Helping a business")}
              </span>
              <br></br>
              <span className="font-Trap whitespace-nowrap bg-gradient-to-r from-[#5EFF6F] to-[#2194F3] inline-block text-transparent bg-clip-text">
                {t("reinvent itself")}
              </span>
            </h1>
            <div class="grid desktop:grid-cols-2 mobile:grid-cols-1 gap-10 desktop:!px-96 !pt-10">
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <span className="font-SpaceGrotesk font-semibold text-[#FFFFFF] desktop:text-6xl mobile:text-6xl desktop:!pb-10 mobile:!pb-4">
                    10X
                  </span>
                </div>
                <div className="font-SpaceGrotesk text-center font-normal text-[#FFFFFF] desktop:text-base mobile:text-base">
                  {t("Google Ratings")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <span className="font-SpaceGrotesk font-semibold text-[#FFFFFF] desktop:text-6xl mobile:text-6xl desktop:!pb-10 mobile:!pb-4">
                    80%
                  </span>
                </div>
                <div className="font-SpaceGrotesk text-center font-normal text-[#FFFFFF] desktop:text-base mobile:text-base">
                  {t("Faster Customer Complaints Response")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <span className="font-SpaceGrotesk font-semibold text-[#FFFFFF] desktop:text-6xl mobile:text-6xl desktop:!pb-10 mobile:!pb-4">
                    74%
                  </span>
                </div>
                <div className="font-SpaceGrotesk text-center font-normal text-[#FFFFFF] desktop:text-base mobile:text-base">
                  {t("decrease in customer complaints annualized.")}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <div>
                  <span className="font-SpaceGrotesk font-semibold text-[#FFFFFF] desktop:text-6xl mobile:text-6xl desktop:!pb-10 mobile:!pb-4">
                    5+
                  </span>
                </div>
                <div className="font-SpaceGrotesk text-center font-normal text-[#FFFFFF] desktop:text-base mobile:text-base">
                  {t("Major Features")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="blog" className="bg-[#FFFFFF]">
      <div class="w-full h-full">
          <div className="w-full h-full flex flex-col desktop:!pt-32 desktop:!pb-4 mobile:!pt-36 mobile:!pb-4">
            <h1 class="text-center font-SpaceGrotesk font-semibold text-[#181818] desktop:text-6xl mobile:text-2xl desktop:!pb-10 mobile:!pb-4">
              <span className="font-Trap whitespace-nowrap">
                {t("Grow with us")}
              </span>
              <div className="desktop:!py-4 mobile:!py-2">
                <button
                  onClick={() => {
                    dispatch({
                      type: LandingDataReducerActionTypes.IS_CALENDLY_OPEN,
                      payload: true,
                    });
                  }}
                  className="inline-flex items-center rounded-[70px] text-base !px-5 !py-3 font-SpaceGrotesk font-medium text-[#FFFFFF] bg-gradient-to-r from-[#5EFF6F] to-[#2194F3] hover:bg-gray-500"
                >
                  <span>{t("Get a Demo")}</span>
                  <svg
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7503 11.0002L14.2201 8.53045C14.513 8.23755 14.513 7.76266 14.2201 7.46976L11.7503 5.00003M14.0004 8.0001L3.00012 8.00011"
                      stroke="white"
                      stroke-width="1.1"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </div>
            </h1>
          </div>
        </div>
        <div class="w-full h-full">
          <div className="w-full h-full flex flex-col desktop:!pt-4 desktop:!pb-32 mobile:!pt-4 mobile:!pb-48">
            <div class="text-center font-SpaceGrotesk desktop:!pb-10 mobile:!pb-4">
              <span className="text-center font-Trap font-semibold text-[#181818] desktop:text-4xl mobile:text-xl">
                {t("Caring is the new marketing")}
              </span>
              <p className="text-center !m-auto !pt-4 font-Trap font-normal text-[#181818] desktop:text-base mobile:text-sm desktop:w-[600px] mobile:w-[300px]">
                {t(
                  "The Trustree blog is the best place to read about the latest membership insights, trends and more. See who's joining the community, read about how our community are dealing with the market, and a lot more.​"
                )}
              </p>
            </div>
            <div class="flex desktop:flex-row mobile:flex-col desktop:justify-evenly mobile:justify-center gap-10 !pt-10 desktop:!px-36 mobile:!px-8">
              <div className="flex flex-col justify-center items-center gap-4 desktop:w-80 mobile:w-full">
                <div>
                  <iframe
                    className="w-full h-60"
                    src="https://www.youtube.com/embed/XyBJfQnahIA?si=8cCl6lOy7WSzrUq8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="font-SpaceGrotesk w-full font-semibold text-[#181818] desktop:text-xl mobile:text-lg">
                  {t("How Chickin Worx, Worxed it")}
                </div>
                <Link
                  to="/blog/chickin-worx-case-study"
                  className="inline-flex items-center font-SpaceGrotesk w-full font-semibold text-[#2194F3] desktop:text-xl mobile:text-base"
                >
                  {t("Readmore")}
                  <svg
                    class="w-4 h-4 ml-4"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 9L15.2929 5.70711C15.6834 5.31658 15.6834 4.68342 15.2929 4.29289L12 1M15 5L1 5"
                      stroke="#2194F3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </Link>
              </div>
              <div className="flex flex-col justify-start items-start gap-4 desktop:w-80 mobile:w-full">
                <div>
                  <iframe
                    className="w-full h-60"
                    src="https://www.youtube.com/embed/YedzGKDBCF8?si=8cCl6lOy7WSzrUq8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="font-SpaceGrotesk w-full font-semibold text-[#181818] desktop:text-xl mobile:text-lg">
                  {t("Trustree Helped Shakour Increase Their Revenue 20%!")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PopupModal
        url="https://calendly.com/trustree/30min"
        onModalClose={() => {
          dispatch({
            type: LandingDataReducerActionTypes.IS_CALENDLY_OPEN,
            payload: false,
          });
        }}
        open={isCalendlyOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
