import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  allDesignLanguages,
  getQuestiostypeApi,
} from "../middlewares/questions";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../components/ButtonComponent";
import Footer from "../components/Footer";
import { Spinner } from "react-bootstrap";

const SurveyHome = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const {
    getQuestionLang,
    getQuestion,
    designLanguage,
    getQuestionloading,
    getQuestionerror,
  } = useSelector((state) => state.question);
  const [languages, setLanguages] = useState([]);
  const { code, brand } = useParams();

  useEffect(() => {
    dispatch(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, code]);

  useEffect(() => {
    dispatch(allDesignLanguages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getQuestionLang && getQuestionLang.length !== null) {
      const selected_languages =
        getQuestionLang &&
        getQuestionLang.length > 0 &&
        getQuestionLang.map((o) => {
          const newId =
            designLanguage &&
            designLanguage.length > 0 &&
            designLanguage.find((obj) => {
              return obj.key === o;
            });
          return newId;
        });
      if (selected_languages && selected_languages.length > 1) {
        const sorter = (a, b) => {
          if (a.key === getQuestion?.brand_design?.default_language) {
            return -1;
          }
          if (b.key === getQuestion?.brand_design?.default_language) {
            return 1;
          }
          return a.key < b.key ? -1 : 1;
        };
        selected_languages.sort(sorter);
      }
      setLanguages(selected_languages);
      localStorage.setItem(
        "button-color",
        getQuestion?.brand_design?.button_colour
      );
      if (selected_languages) {
        if (getQuestionLang && getQuestionLang.length < 2) {
          i18n.changeLanguage(selected_languages[0]?.key);
          nav(`/${getQuestion.brand_name}/${getQuestion.code}/reward`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionLang, designLanguage]);

  const handleOnClick = (lang) => {
    i18n.changeLanguage(lang.key);
    nav(`/${getQuestion.brand_name}/${getQuestion.code}/reward`);
  };
  let buttonColor = localStorage.getItem("button-color");

  if (getQuestionloading) {
    return (
      <div className="PCresponse">
        <Spinner
          style={{ color: `${buttonColor ? buttonColor : "#164665"}` }}
          animation="border"
          variant="secondary"
        />
        <Footer title={"home"} questionData={getQuestion} />
      </div>
    );
  }

  if (getQuestionerror) {
    return (
      <div className="PCresponse">
        <div className="paddingApp">
          <div className="surveyBtn">
            <div className="NavLogo">
              {getQuestionerror.logo ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${getQuestionerror.logo}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://api.app.trustree.com/images/Brandlogo.png";
                  }}
                  alt="logo"
                  loading="lazy"
                />
              ) : null}
            </div>
            {t(getQuestionerror.message)}
          </div>
        </div>
        <Footer title={"home"} questionData={getQuestion} />
      </div>
    );
  }
  return (
    <div className="PCresponse">
      <div className="paddingApp">
        <div className="surveyBtn">
          <div className="NavLogo">
            {getQuestion?.brand_design?.show_logo ? (
              <img
                src={
                  `${process.env.REACT_APP_BASE_URL}${getQuestion?.brand_detail?.logo}` ||
                  require("../assets/images/BrandLogo.png")
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://api.app.trustree.com/images/Brandlogo.png";
                }}
                alt="logo"
                loading="lazy"
              />
            ) : null}
          </div>
          <div className="multiButton">
            {languages.map((languages, i) => {
              return (
                <div className="mb-3">
                  <ButtonComponent
                    buttonType={"button"}
                    clickFunction={() => handleOnClick(languages)}
                    buttonLabel={languages.value}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer title={"home"} questionData={getQuestion} />
    </div>
  );
};

export default SurveyHome;
