import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import IndexedDBServices from "../services/indexedDB-services";
import AllImages from "./AllImages";
import {
  OBJECT_STORE_ANSWERS,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";

function SelectionRatingQuestion(props) {
  const nav = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const {
    getQuestion,
    getAllQuestions,
    getStaffDetails,
    getProductDetails,
    getServiceDetails,
    answers,
  } = useSelector((state) => state.question);
  const brand_color = localStorage.getItem("button-color");

  useEffect(() => {
    if (params.question_id !== props.object._id) {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    IndexedDBServices.checkDBdata(params, nav);

    if (getAllQuestions[params.page - 2]?.type === "single") {
      const questionIndex = answers?.findIndex(
        (o) => o?.id === getAllQuestions[params.page - 2]?._id
      );
      IndexedDBServices.addFeedbackAnswers(
        answers[
          questionIndex && questionIndex > 0
            ? questionIndex
            : answers?.length - 1
        ]
      );
    } else {
      const questionIndex = answers?.findIndex(
        (o) => o?.question_id === getAllQuestions[params.page - 2]._id
      );
      IndexedDBServices.addFeedbackAnswers(
        answers[
          questionIndex && questionIndex > 0
            ? questionIndex
            : answers?.length - 1
        ]
      );
    }
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_ANSWERS)) {
        nav(`/${params.brand}/${params.code}/reward`);
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const handleClickSkip = () => {
    let questionLength = getAllQuestions.length;
    let page = parseInt(params.page);
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };

  const ref = useRef([]);
  const [showTooltip, setShowTooltip] = useState([]);
  const blankArr = [];

  useEffect(() => {
    if (ref !== undefined) {
      const dive = ref.current;
      const finalArr = dive?.filter((j) => {
        return j !== null;
      });
      finalArr.length > 0 &&
        finalArr.map((o) => {
          const isOverflow = o?.offsetHeight < o?.scrollHeight;
          blankArr.push(isOverflow);
          setShowTooltip(blankArr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="paddingApp">
        <h6 className="globalText">
          {props.object.name?.[t("language")]
            ? props.object.name?.[t("language")]
            : props.object.name?.[t("en")]}
        </h6>
        <div className="ProductTabs stickyTab">
          {props.object.type === "staff" ? (
            <div className="sandos-box">
              <ul>
                <li className="QuestionFlex">
                  {getStaffDetails &&
                    getStaffDetails.length > 0 &&
                    getStaffDetails.map((o, i) => {
                      return (
                        <div className="BoxRate" key={i}>
                          <LinkContainer
                            to={`/${params.brand}/${params.code}/${
                              params.page
                            }/${props.object._id}/${props.object.type}/${
                              o._id
                            }/${
                              o.name?.[t("language")]
                                ? o.name?.[t("language")]
                                : o.name?.[t("en")]
                            }`}
                          >
                            <div>
                              <AllImages logo={o.profile_pic} id={o._id} />
                              <>
                                {showTooltip[i] === true ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={`tooltip-${o.name}`}>
                                        <p>
                                          {o.name?.[t("language")]
                                            ? o.name?.[t("language")]
                                            : o.name?.[t("en")]}
                                        </p>
                                      </Tooltip>
                                    }
                                  >
                                    <p>
                                      {o.name?.[t("language")]
                                        ? o.name?.[t("language")]
                                        : o.name?.[t("en")]}
                                    </p>
                                  </OverlayTrigger>
                                ) : (
                                  <p
                                    ref={(element) => ref.current.push(element)}
                                  >
                                    {o.name?.[t("language")]
                                      ? o.name?.[t("language")]
                                      : o.name?.[t("en")]}
                                  </p>
                                )}
                              </>
                            </div>
                          </LinkContainer>
                        </div>
                      );
                    })}
                </li>
              </ul>
            </div>
          ) : (
            <Tabs
              defaultActiveKey={`${
                getProductDetails[0]?.title
                  ? getProductDetails[0]?.title
                  : getServiceDetails[0]?.title
              }-0`}
              className="mb-3"
            >
              {props.object.type === "product"
                ? getProductDetails &&
                  getProductDetails.length > 0 &&
                  getProductDetails.map((o, i) => {
                    return (
                      <Tab
                        key={i}
                        eventKey={`${o.title}-${i}`}
                        title={
                          o.title?.[t("language")]
                            ? o.title?.[t("language")]
                            : o.title?.[t("en")]
                        }
                        tabAttrs={{
                          style: {
                            borderColor: ` ${
                              brand_color ? brand_color : "#ed1c24"
                            } `,
                          },
                        }}
                      >
                        <div className="sandos-box">
                          <ul>
                            <li className="QuestionFlex">
                              {o.products.map((j, index) => {
                                return (
                                  <div className="BoxRate" key={index}>
                                    <LinkContainer
                                      to={`/${params.brand}/${params.code}/${
                                        params.page
                                      }/${props.object._id}/${
                                        props.object.type
                                      }/${j._id}/${
                                        j.name?.[t("language")]
                                          ? j.name?.[t("language")]
                                          : j.name?.[t("en")]
                                      }`}
                                    >
                                      <div>
                                        <AllImages logo={j.logo} id={j._id} />
                                        <>
                                          {showTooltip[index] === true ? (
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${o.name}`}
                                                >
                                                  <p>
                                                    {j.name?.[t("language")]
                                                      ? j.name?.[t("language")]
                                                      : j.name?.[t("en")]}
                                                  </p>
                                                </Tooltip>
                                              }
                                            >
                                              <p>
                                                {j.name?.[t("language")]
                                                  ? j.name?.[t("language")]
                                                  : j.name?.[t("en")]}
                                              </p>
                                            </OverlayTrigger>
                                          ) : (
                                            <p
                                              ref={(element) =>
                                                ref.current.push(element)
                                              }
                                            >
                                              {j.name?.[t("language")]
                                                ? j.name?.[t("language")]
                                                : j.name?.[t("en")]}
                                            </p>
                                          )}
                                        </>
                                      </div>
                                    </LinkContainer>
                                  </div>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      </Tab>
                    );
                  })
                : getServiceDetails &&
                  getServiceDetails.length > 0 &&
                  getServiceDetails.map((o, i) => {
                    return (
                      <Tab
                        key={i}
                        eventKey={`${o.title}-${i}`}
                        title={
                          o.title?.[t("language")]
                            ? o.title?.[t("language")]
                            : o.title?.[t("en")]
                        }
                        tabAttrs={{
                          style: {
                            borderColor: ` ${
                              brand_color ? brand_color : "#ed1c24"
                            } `,
                          },
                        }}
                      >
                        <div className="sandos-box">
                          <ul>
                            <li className="QuestionFlex">
                              {o.services.map((j, index) => {
                                return (
                                  <div className="BoxRate" key={index}>
                                    <LinkContainer
                                      to={`/${params.brand}/${params.code}/${
                                        params.page
                                      }/${props.object._id}/${
                                        props.object.type
                                      }/${j._id}/${
                                        j.name?.[t("language")]
                                          ? j.name?.[t("language")]
                                          : j.name?.[t("en")]
                                      }`}
                                    >
                                      <div key={index}>
                                        <AllImages logo={j.image} id={j._id} />
                                        <>
                                          {showTooltip[index] === true ? (
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${o.name}`}
                                                >
                                                  <p>
                                                    {j.name?.[t("language")]
                                                      ? j.name?.[t("language")]
                                                      : j.name?.[t("en")]}
                                                  </p>
                                                </Tooltip>
                                              }
                                            >
                                              <p>
                                                {j.name?.[t("language")]
                                                  ? j.name?.[t("language")]
                                                  : j.name?.[t("en")]}
                                              </p>
                                            </OverlayTrigger>
                                          ) : (
                                            <p
                                              ref={(element) =>
                                                ref.current.push(element)
                                              }
                                            >
                                              {j.name?.[t("language")]
                                                ? j.name?.[t("language")]
                                                : j.name?.[t("en")]}
                                            </p>
                                          )}
                                        </>
                                      </div>
                                    </LinkContainer>
                                  </div>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      </Tab>
                    );
                  })}
            </Tabs>
          )}{" "}
        </div>
        {props.object?.optional === false ? null : (
          <span
            style={{
              color: `${
                getQuestion?.brand_design?.button_colour
                  ? getQuestion?.brand_design?.button_colour
                  : "#000000"
              }`,
              textDecoration: "none",
              cursor: "pointer",
            }}
            className="skipBtn"
            onClick={(e) => handleClickSkip(e)}
          >
            {t("SKIP")}
          </span>
        )}
      </div>
    </>
  );
}

export default SelectionRatingQuestion;
