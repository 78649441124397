import { useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "../../../components/landing/NavBar";
import Footer from "../../../components/landing/Footer";

import "./GetStartedPage.css";

import { InlineWidget } from "react-calendly";

const GetStartedPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-[#FFF4EA]">
      <NavBar></NavBar>
      <section id="get-started">
        <div class="w-full h-full pt-48 pb-24 px-3 relative">
          <InlineWidget className="h-full" url="https://calendly.com/trustree/30min" />
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default GetStartedPage;
