import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import backArrow from "../assets/images/backArrow.svg";
import BrandLogo from "./BrandLogo";
import ProgressiveBar from "./ProgressiveBar";
import { useSelector } from "react-redux";

const BackArrow = (props) => {
  const params = useParams();
  const page = parseInt(params.page);
  const { getAllQuestions } = useSelector((state) => state.question);
  const nav = useNavigate();

  const handleBackArrow = () => {
    if (page === 1 || !page) {
      nav(`/${params.brand}/${params.code}/reward`);
    } else if (params.type) {
      nav(
        `/${params.brand}/${params.code}/${page}/${
          getAllQuestions[page - 1]._id
        }`
      );
    } else if (!params.type) {
      nav(
        `/${params.brand}/${params.code}/${page - 1}/${
          getAllQuestions[page - 2]._id
        }`
      );
    }
  };
  return (
    <div className=" BackArrrow_Btn">
      <div className="navLogo">
        <div className="navBack">
          <img
            loading="lazy"
            alt="backArrow"
            src={backArrow}
            onClick={handleBackArrow}
          />
        </div>
        <BrandLogo object={props.data} />
      </div>
      <ProgressiveBar />
    </div>
  );
};

export default BackArrow;
