import React from "react";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import IndexedDBServices from "../services/indexedDB-services";
import { useDispatch, useSelector } from "react-redux";
import { addAnswers } from "../slices/slice";
import { useState } from "react";
import { useEffect } from "react";
import { CATEGORY_RATING, STORE_NAME, STORE_VERSION } from "../helper/constant";
import { useTranslation } from "react-i18next";
// import "bootstrap/dist/css/bootstrap.min.css";
import ButtonComponent from "./ButtonComponent";

const CategoryRating = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const nav = useNavigate();
  const page = parseInt(params.page);
  const [ratingData, setRatingData] = useState({
    type: props.object.type,
    id: "",
    questionName: props.object.name,
    colour: "",
    rating: 0,
    name: "",
    sequence: props.object.sequence,
  });
  const { getAllQuestions } = useSelector((state) => state.question);

  useEffect(() => {
    if (params.question_id !== props.object._id) {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [backData, setBackData] = useState();
  let [data] = useState({ id: props.object?._id, main: [] });
  const [arrayState, setArrayState] = useState([]);
  const selectedLanguage = localStorage.getItem("i18nextLng");

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(CATEGORY_RATING)) {
        nav(`/${params.brand}/${params.code}/reward`);
      } else {
        const tx = db.transaction(CATEGORY_RATING, "readwrite");
        const categoryData = tx.objectStore(CATEGORY_RATING);
        const cat = categoryData.getAll();
        cat.onsuccess = (query) => {
          let data1 = query.srcElement.result;

          var a = data1[0]?.main;

          data.main = data1[0]?.main;
          setBackData(a);
          setArrayState(a || []);
        };
      }
      db.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRating = (r, i, name, id, colour) => {
    let rate = (ratingData.rating = r);
    let names = (ratingData.name = name);
    let ids = (ratingData.id = id);
    let color = (ratingData.colour = colour);
    let obj = {
      type: props.object.type,
      id: ids,
      questionName: props.object.name,
      rating: rate,
      colour: color,
      name: names,
      sequence: props.object.sequence,
    };

    setRatingData(obj);
    if (backData) {
      data.main = [...backData];
    }
    const matchId = data?.main?.findIndex((o) => o.id === ratingData.id);
    let finalObject = arrayState;

    if (matchId > -1) {
      finalObject.splice(matchId, 1, ratingData);
    } else {
      finalObject.push(ratingData);
    }

    setArrayState(finalObject);
    data.main = finalObject;
  };

  const handleClickNext = () => {
    dispatch(addAnswers(data));
    IndexedDBServices.addCategoryAnswers(data);
    let questionLength = props.data.length;
    let page = parseInt(params.page);
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };

  return (
    <>
      <div className="paddingApp">
        <h6 className="globalText">
          {props.object.name?.[t("language")]
            ? props.object.name?.[t("language")]
            : props.object.name?.[t("en")]}
        </h6>
        <div className="StarRatting">
          <div className="Nestbtn">
            {props.object?.category_option.map((o, index) => {
              return (
                <div className="category" key={index}>
                  <h5>
                    {o.name?.[t("language")]
                      ? o.name?.[t("language")]
                      : o.name?.[t("en")]}
                  </h5>
                  <Rating
                    rtl={
                      selectedLanguage === "ar" ||
                      selectedLanguage === "fa" ||
                      selectedLanguage === "he" ||
                      selectedLanguage === "ur"
                        ? true
                        : false
                    }
                    id={index}
                    initialValue={
                      page === 1
                        ? backData &&
                          backData[
                            backData.findIndex(
                              (o, i) =>
                                o.id ===
                                props.object?.category_option[index]?._id
                            )
                          ]?.rating
                        : ratingData && ratingData[index]?.rating
                    }
                    onClick={(val) =>
                      handleRating(val, index, o?.name, o._id, o?.colour)
                    }
                    transition
                  />
                </div>
              );
            })}
            <ButtonComponent
              buttonDisabled={
                data &&
                data.main &&
                data.main.length === props.object.category_option.length
                  ? false
                  : true
              }
              buttonType={"button"}
              object={props.object}
              clickFunction={() => handleClickNext()}
              buttonLabel={[t("NEXT")]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryRating;
