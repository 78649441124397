import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const ButtonComponent = (props) => {
  const { getQuestion } = useSelector((state) => state.question);
  return (
    <>
      <Button
        className="btn btn-primary"
        type={props.buttonType}
        disabled={props.buttonDisabled}
        style={{
          backgroundColor: `${
            getQuestion?.brand_design?.button_colour
              ? getQuestion?.brand_design?.button_colour
              : "#164665"
          }`,
          color: `${
            getQuestion?.brand_design?.button_text_colour
              ? getQuestion?.brand_design?.button_text_colour
              : "#00000"
          }`,
          borderColor: `${
            getQuestion?.brand_design?.button_colour
              ? getQuestion?.brand_design?.button_colour
              : "#164665"
          }`,
        }}
        onClick={props.clickFunction}
      >
        {props.buttonLabel}
      </Button>
    </>
  );
};

export default ButtonComponent;
