import React from "react";
import { useTranslation } from "react-i18next";
import rewardImg from "../../assets/images/reward.svg";

const Success = () => {
  const { t } = useTranslation();
  return (
    <div className="redeemVoucher">
      <div className="code-border giftCode">
        <img loading="lazy" src={rewardImg} alt="rewardImg" />
        <h6>{[t("SUCCESSFULLY_CLAIMED")]}</h6>
      </div>
    </div>
  );
};

export default Success;
