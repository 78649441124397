import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getQuestiostypeApi } from "../middlewares/questions";
import CategoryRating from "../components/CategoryRating";
import MultipleTypeQuestion from "../components/MultipleTypeQuestion";
import SelectionRatingQuestion from "../components/SelectionRatingQuestion";
import SingleTypeQuestion from "../components/SingleTypeQuestion";
import SurveyHome from "./SurveyHome";
import BackArrow from "../components/BackArrow";
import Footer from "../components/Footer";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PageRender = () => {
  const {
    getQuestion,
    getQuestionloading,
    getAllQuestions,
    getAllQuestions: questions,
    getQuestionerror,
  } = useSelector((state) => state.question);
  const [activeQuestions, setActiveQuestions] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  const { page, brand, code } = params;
  const buttonColor = localStorage.getItem("button-color");
  const { t } = useTranslation();
  useEffect(() => {
    if (page) {
      setActiveQuestions(questions[page - 1]);
    } else {
      setActiveQuestions(questions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, questions]);

  useEffect(() => {
    dispatch(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQuestion = (question) => {
    if (question.type === "category rating") {
      return <CategoryRating object={question} data={getAllQuestions} />;
    } else if (question.type === "single") {
      return <SingleTypeQuestion object={question} data={getAllQuestions} />;
    } else if (
      question.type === "staff" ||
      question.type === "product" ||
      question.type === "service"
    ) {
      return (
        <SelectionRatingQuestion object={question} data={getAllQuestions} />
      );
    } else if (question.type === "multiple") {
      return <MultipleTypeQuestion object={question} data={getAllQuestions} />;
    } else if (!question) {
      return <SurveyHome />;
    }
  };

  if (getQuestionerror) {
    return (
      <div className="PCresponse">
        <div className="paddingApp">
          <div className="surveyBtn">
            <div className="NavLogo">
              {getQuestionerror.logo ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${getQuestionerror.logo}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://api.app.trustree.com/images/Brandlogo.png";
                  }}
                  alt="logo"
                  loading="lazy"
                />
              ) : null}
            </div>
            {t(getQuestionerror.message)}
          </div>
        </div>
        <Footer title={"home"} questionData={getQuestion} />
      </div>
    );
  }

  return (
    <div className="PCresponse">
      {getQuestionloading ? (
        <Spinner
          style={{ color: `${buttonColor ? buttonColor : "#164665"}` }}
          animation="border"
          variant="secondary"
        />
      ) : (
        <>
          <BackArrow data={getQuestion} />
          {activeQuestions ? (
            <>{renderQuestion(activeQuestions)}</>
          ) : (
            <>No question found !</>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

export default PageRender;
