import { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "./../../../assets/images/landing/logo.svg";
import { Link } from "react-router-dom";

import { useMainContextDataLayerValue } from "../../../context/MainContextDataLayer";
import LandingDataReducerActionTypes from "../../../context/MainContextDataTypes";
import { NavHashLink } from "react-router-hash-link";

const NavBar = () => {
  const { t, i18n } = useTranslation();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const [{ isCalendlyOpen }, dispatch] = useMainContextDataLayerValue();

  const getNextLanguageName = () => {
    if (i18n.resolvedLanguage === "en") {
      return t("Arabic");
    } else if (i18n.resolvedLanguage === "ar") {
      return t("English");
    } else {
      return t("Arabic");
    }
  };

  return (
    <nav
      className="z-50 flex bg-white items-center justify-between !py-4 desktop:!px-16 mobile:!pr-4 mobile:!pl-6 fixed w-full top-0 h-24 relative "
      style={{
        borderStyle: "solid",
        borderWidth: "0 0 2px 0",
        borderImage: "linear-gradient(45deg, #5EFF6F, #2194F3) 1",
      }}
    >
      <div className=" flex items-center flex-shrink-0 text-white w-1/6">
        <Link
          className=" text-white no-underline hover:text-white hover:no-underline"
          to="/home"
        >
          <img src={Logo}></img>
        </Link>
      </div>

      <button
        type="button"
        onClick={() => {
          setIsSideBarOpen(!isSideBarOpen);
        }}
        className=" block desktop:hidden  text-gray-500 hover:text-white focus:outline-none focus:text-white"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 7H21"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M3 12H21"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M3 17H21"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </button>

      <div
        className={` mobile:absolute mobile:top-0 mobile:left-0 mobile:!mt-24 w-full flex-grow desktop:flex desktop:items-center desktop:w-auto bg-white ${
          isSideBarOpen ? "" : "hidden"
        }`}
      >
        <div className="flex desktop:flex-row mobile:flex-col w-full">
          <div className="grow w-3/4 mobile:w-full mobile:!py-2">
            <div className="h-full">
              <div className="flex desktop:justify-center desktop:gap-20 desktop:flex-row mobile:flex-col items-center h-full desktop:text-right mobile:text-center">
                <div className="mobile:!py-2">
                  <NavHashLink
                    smooth
                    to="/home#features"
                    className="text-[#313131] font-SpaceGrotesk font-medium text-lg hover:text-[#A8A9AD] no-underline"
                  >
                    {t("Features")}
                  </NavHashLink>
                </div>
                <div className="mobile:!py-2">
                  <NavHashLink
                    smooth
                    to="/home#blog"
                    className="text-[#313131] font-SpaceGrotesk font-medium text-lg hover:text-[#A8A9AD] no-underline"
                  >
                    {t("Blog")}
                  </NavHashLink>
                </div>
                <div className="mobile:!py-2">
                  <Link
                    target="_blank"
                    to="https://calendly.com/inframodern/30min"
                    className="text-[#313131] font-SpaceGrotesk font-medium text-lg hover:text-[#A8A9AD] no-underline"
                  >
                    {t("Request A Demo")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex-none w-1/4 mobile:w-full mobile:!py-2">
            <div className=" desktop:text-right mobile:text-center">
              <div className=" flex desktop:flex-row mobile:flex-col desktop:justify-end desktop:gap-4 h-full items-center">
                <div className="mobile:!py-2">
                  <div className="inline-block relative">
                    {/* <button
                      onClick={() => {
                        if (i18n.resolvedLanguage === "en") {
                          i18n.changeLanguage("ar");
                          window.location.reload();
                        } else if (i18n.resolvedLanguage === "ar") {
                          i18n.changeLanguage("en");
                          window.location.reload();
                        } else {
                          i18n.changeLanguage("en");
                          window.location.reload();
                        }
                      }}
                      className=" text-[#313131] font-SpaceGrotesk font-medium text-lg inline-flex items-center"
                    >
                      <span className="text-[#313131] font-SpaceGrotesk font-medium text-lg mr-1">
                        {getNextLanguageName()}
                      </span>
                    </button> */}
                  </div>
                </div>
                <div className="mobile:!py-2">
                  <a
                    href="https://app.trustree.com/"
                    className="rounded-[70px] border-2 border-[#181818] hover:border-gray-500 text-[#181818] !px-5 !py-3 font-SpaceGrotesk font-medium text-lg hover:text-gray-500 no-underline"
                  >
                    {t("Login")}
                  </a>
                </div>
                <div className="mobile:!py-2">
                  <button
                    onClick={() => {
                      dispatch({
                        type: LandingDataReducerActionTypes.IS_CALENDLY_OPEN,
                        payload: true,
                      });
                    }}
                    className="rounded-[70px] text-lg !px-5 !py-3 font-SpaceGrotesk font-medium text-[#FFFFFF] bg-[#181818] hover:bg-gray-500"
                  >
                    {t("Sign up")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
