import { useTranslation } from "react-i18next";

const ScrollingFeatureText = ({ text }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className=" flex justify-center items-center desktop:gap-4 mobile:gap-3">
      <div className="align-middle desktop:w-4 desktop:h-4 mobile:w-3 mobile:h-3">
        <svg
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8.5" r="8" fill="#FFFFFF" />
        </svg>
      </div>
      <div className="align-middle">
        <span className="text-[#FFFFFF] font-SpaceGrotesk font-semibold desktop:text-3xl mobile:text-lg">
          {text}
        </span>
      </div>
    </div>
  );
};

export default ScrollingFeatureText;
