import { useNavigate, Link } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "./../../../components/landing/NavBar";
import Footer from "./../../../components/landing/Footer";
import ScrollingFeatureText from "./../../../components/landing/ScrollingFeatureText";
import CWCaseStudyImage from "./../../../assets/images/landing/cwcasestudy.svg";
import HeroArrow from "./../../../assets/images/landing/heroarrow.svg";
import DemoArrow from "./../../../assets/images/landing/demoarrow.svg";
import DemoQR from "./../../../assets/images/landing/demoqr.svg";
import MoreArrowDown from "./../../../assets/images/landing/morearrowdown.svg";

import "./BlogPage.css";

const BlogPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-[#FFFFFF]">
      <NavBar></NavBar>
      <section id="blog">
        <div className="w-full h-full desktop:!pt-48 mobile:!pt-28 !pb-24 desktop:!px-20 mobile:!px-5">
          <div className="font-bold text-3xl font-SpaceGrotesk">
            {t("Trustree Blog")}
          </div>
          <div className="font-normal text-lg text-[#00000080] font-SpaceGrotesk !mt-2">
            {t(
              "Articles on Customer Service, Customer Support, Product Design, and more"
            )}
          </div>
          <div className="bg-[#FFFFFF] rounded-lg border-2 border-[#000000] w-full h-auto !p-4 !mt-16">
            <div className="flex desktop:flex-row mobile:flex-col gap-6 h-full relative">
              <div className="desktop:w-1/3 h-full">
                <img className="rounded-2xl h-full" src={CWCaseStudyImage} />
              </div>
              <div className="desktop:w-2/3 h-full">
                <Link
                  to={"/blog/chickin-worx-case-study"}
                  className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4"
                >
                  {t(
                    "How did Chickin Worx use Trustree to create a a cult of Worxers?"
                  )}
                </Link>
                <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm !mt-2">
                  Mon, 6 February 2023 • NEWS & UPDATES
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4 !mb-14">
                  {t(
                    "Chickin Worx was able to use Trustree to become one of the most customer centric businesses in the Middle East. They are currently the most highly rated restaurant in Egypt on Google. The management at Chickin Worx really understands the importance of putting the customer at the center of the main stage. Chickin Worx pin points Trustree as a main tool and instrument that helped them raise their customer experience standards tremendously."
                  )}
                </div>
                <div className="desktop:!py-4 mobile:!py-2">
                  <Link
                    to={"/blog/chickin-worx-case-study"}
                    className="!mb-20 inline-flex text-center justify-center items-center w-60 rounded-[70px] text-base !px-5 !py-3 font-SpaceGrotesk font-medium text-[#FFFFFF] bg-gradient-to-r from-[#5EFF6F] to-[#2194F3] hover:bg-gray-500"
                  >
                    <span>{t("Read More")}</span>
                    <svg
                      class="w-4 h-4 ml-2"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7503 11.0002L14.2201 8.53045C14.513 8.23755 14.513 7.76266 14.2201 7.46976L11.7503 5.00003M14.0004 8.0001L3.00012 8.00011"
                        stroke="white"
                        stroke-width="1.1"
                        stroke-linecap="round"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="flex gap-4 absolute bottom-0">
                  <div className="">
                    <div className="relative w-12 h-w-12">
                      <img
                        className="rounded-full border border-gray-100 shadow-sm"
                        src={require("./../../../assets/images/landing/man.png")}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="">
                      <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                        Mahmoud Mokhtar
                      </div>
                    </div>
                    <div className="">
                      <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                        Product Designer, Trustree
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid desktop:grid-cols-2 mobile::grid-cols-1 gap-12 !mt-16">
            <div className="bg-[#FFFFFF] rounded-lg border-2 border-[#000000] w-full h-auto !p-4">
              <div className="flex flex-col gap-6 h-full relative">
                <div className="w-full h-full">
                  <img
                    className="rounded-2xl h-full w-full"
                    src={require("./../../../assets/images/landing/blogpostimage.png")}
                  />
                </div>
                <div className="w-full h-full">
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm !mt-2">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4 !mb-14">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </div>
                  <div className="flex gap-4 absolute bottom-0">
                    <div className="">
                      <div className="relative w-12 h-w-12">
                        <img
                          className="rounded-full border border-gray-100 shadow-sm"
                          src={require("./../../../assets/images/landing/man.png")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Mahmoud Mokhtar
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Product Designer, Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#FFFFFF] rounded-lg border-2 border-[#000000] w-full h-auto !p-4">
              <div className="flex flex-col gap-6 h-full relative">
                <div className="w-full h-full">
                  <img
                    className="rounded-2xl h-full w-full"
                    src={require("./../../../assets/images/landing/blogpostimage.png")}
                  />
                </div>
                <div className="w-full h-full">
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm !mt-2">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4 !mb-14">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </div>
                  <div className="flex gap-4 absolute bottom-0">
                    <div className="">
                      <div className="relative w-12 h-w-12">
                        <img
                          className="rounded-full border border-gray-100 shadow-sm"
                          src={require("./../../../assets/images/landing/man.png")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Mahmoud Mokhtar
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Product Designer, Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#FFFFFF] rounded-lg border-2 border-[#000000] w-full h-auto !p-4">
              <div className="flex flex-col gap-6 h-full relative">
                <div className="w-full h-full">
                  <img
                    className="rounded-2xl h-full w-full"
                    src={require("./../../../assets/images/landing/blogpostimage.png")}
                  />
                </div>
                <div className="w-full h-full">
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm !mt-2">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4 !mb-14">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </div>
                  <div className="flex gap-4 absolute bottom-0">
                    <div className="">
                      <div className="relative w-12 h-w-12">
                        <img
                          className="rounded-full border border-gray-100 shadow-sm"
                          src={require("./../../../assets/images/landing/man.png")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Mahmoud Mokhtar
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Product Designer, Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#FFFFFF] rounded-lg border-2 border-[#000000] w-full h-auto !p-4">
              <div className="flex flex-col gap-6 h-full relative">
                <div className="w-full h-full">
                  <img
                    className="rounded-2xl h-full w-full"
                    src={require("./../../../assets/images/landing/blogpostimage.png")}
                  />
                </div>
                <div className="w-full h-full">
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm !mt-2">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                  <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4 !mb-14">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s.
                  </div>
                  <div className="flex gap-4 absolute bottom-0">
                    <div className="">
                      <div className="relative w-12 h-w-12">
                        <img
                          className="rounded-full border border-gray-100 shadow-sm"
                          src={require("./../../../assets/images/landing/man.png")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Mahmoud Mokhtar
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Product Designer, Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="!mt-20 flex items-center justify-center">
            <button className="flex gap-4">
              <div className="whitespace-pre-line text-[#000000] font-SpaceGrotesk font-medium text-2xl">
                {t("Load more posts")}
              </div>
              <div className="">
                <img className="" src={MoreArrowDown} />
              </div>
            </button>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default BlogPage;
