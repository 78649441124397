import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "./../../../components/landing/NavBar";
import Footer from "./../../../components/landing/Footer";
import ScrollingFeatureText from "./../../../components/landing/ScrollingFeatureText";
import CWCaseStudyImage from "./../../../assets/images/landing/cwcasestudy.svg";
import HeroArrow from "./../../../assets/images/landing/heroarrow.svg";
import DemoArrow from "./../../../assets/images/landing/demoarrow.svg";
import DemoQR from "./../../../assets/images/landing/demoqr.svg";
import MoreArrowDown from "./../../../assets/images/landing/morearrowdown.svg";

import "./BlogPostPage.css";
import { useEffect, useState } from "react";

const BlogPostPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  let { blogPostId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    console.log(`/blog/${blogPostId}`);
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="bg-[#FFFFFF]">
      <NavBar></NavBar>
      <section id="blog">
        <div className="w-full h-full desktop:!pt-20 mobile:!pt-28 !pb-24 desktop:!px-20 mobile:!px-5">
          <div
            class={`flex justify-center items-center ${
              isLoading ? "block" : "hidden"
            }`}
          >
            <div
              class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div
            className={`bg-white rounded-2xl w-full !p-4 !mt-16 ${
              isLoading ? "hidden" : "block"
            }`}
          >
            <div className="flex flex-col gap-6 w-full h-full">
              <div className="w-full h-full">
                <img
                  className="rounded-2xl w-full max-h-[550px]"
                  src={require("./../../../assets/images/landing/blogpostimage.png")}
                />
              </div>
              <div className="w-full h-full relative">
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </div>
                <div className="flex desktop:flex-row mobile:flex-col desktop:items-center mobile:items-start justify-start desktop:gap-14 mobile:gap-3 !mt-2">
                  <div className="flex gap-4">
                    <div className="">
                      <div className="relative w-12 h-w-12">
                        <img
                          className="rounded-full border border-gray-100 shadow-sm"
                          src={require("./../../../assets/images/landing/man.png")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Mahmoud Mokhtar
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Product Designer, Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default BlogPostPage;
