import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import React from "react";
import SurveyHome from "./pages/SurveyHome";
import HomeReward from "./pages/HomeReward";
import ROUTE_URL from "./config/routes";
import PageRender from "./pages/PageRender";
import SpecificRating from "./components/SpecificRating";
import Information from "./pages/Information";
import SocialMedia from "./pages/SocialMedia";
import Menu from "./pages/productandserviceTabs/Menu";
import ServiceList from "./pages/productandserviceTabs/ServiceList";
import {
  STORE_CURRENT_TIME,
  STORE_NAME,
  STORE_VERSION,
} from "./helper/constant";
import IndexedDBServices from "./services/indexedDB-services";
import ConfirmationModal from "./pages/redeem/ConfirmationModal";
import Success from "./pages/redeem/Success";
import ThankYou from "./pages/redeem/ThankYou";

import Layout from "./components/landing/Layout";
import HomePage from "./pages/landing/HomePage";
import DemoPage from "./pages/landing/DemoPage";
import BlogPage from "./pages/landing/BlogPage";
import ChickinWorxCaseStudyBlogPostPage from "./pages/landing/BlogPostPages/ChickinWorxCaseStudyBlogPostPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetStartedPage from "./pages/landing/GetStartedPage";

import { MainContextDataLayer } from "./context/MainContextDataLayer";
import MainContextDataReducer, {
  MainContextDataReducerInitialState,
} from "./context/MainContextDataReducer";
import BlogPostPage from "./pages/landing/BlogPostPage";
import Error404 from "./pages/redeem/Error404";

function App() {
  const body = document.querySelector("body");
  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (
      language === "ar" ||
      language === "fa" ||
      language === "he" ||
      language === "ur"
    ) {
      if (body) {
        document.querySelector("body").classList.add("arabic");

        if (body.classList.contains("arabic")) {
          document.getElementsByTagName("html")[0].lang = language;
          document.getElementsByTagName("html")[0].dir = "rtl";
        }
      }
    } else {
      if (body) {
        if (body.classList.contains("arabic")) {
          document.querySelector("body").classList.remove("arabic");
          document.getElementsByTagName("html")[0].lang = language;
          document.getElementsByTagName("html")[0].dir = "";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const currentTime = { id: 1, time: new Date().getTime() };
    IndexedDBServices.storeCurrentTime(currentTime);

    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (db.objectStoreNames.contains(STORE_CURRENT_TIME)) {
        const tx = db.transaction(STORE_CURRENT_TIME, "readwrite");
        const timeData = tx.objectStore(STORE_CURRENT_TIME);
        const time = timeData.getAll();
        time.onsuccess = (query) => {
          let stored_time_data = query.srcElement.result[0].time;
          const current_time = new Date().getTime();
          const delete_time = current_time - stored_time_data;
          if (delete_time >= 86400000) {
            IndexedDBServices.clearObjecrtStoreData();
            window.location.reload();
          }
        };
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <MainContextDataLayer
        reducer={MainContextDataReducer}
        intialState={MainContextDataReducerInitialState}
      >
        <Routes>
          <Route path={ROUTE_URL.LANDING} element={<HomePage />} />
          <Route path={ROUTE_URL.LANDING_HOME} element={<HomePage />} />
          <Route path={ROUTE_URL.LANDING_DEMO} element={<DemoPage />} />
          <Route path={ROUTE_URL.LANDING_BLOG} element={<BlogPage />} />
          <Route path={"/blog/chickin-worx-case-study"} element={<ChickinWorxCaseStudyBlogPostPage />} />
          <Route
            path={ROUTE_URL.LANDING_GET_STARTED}
            element={<GetStartedPage />}
          />
          <Route
            path={ROUTE_URL.LANDING_BLOGPOST_VIEW}
            element={<BlogPostPage />}
          />
          <Route path={ROUTE_URL.HOME} element={<SurveyHome />} />
          <Route path={ROUTE_URL.HOME_REWARD} element={<HomeReward />} />
          <Route exact path={ROUTE_URL.MAIN_ROUTE} element={<PageRender />} />
          <Route path={ROUTE_URL.REWARD_MENU} element={<Menu />} />
          <Route path={ROUTE_URL.SERVICE_LIST} element={<ServiceList />} />
          <Route
            path={ROUTE_URL.SPECIFIC_RATING}
            element={<SpecificRating />}
          />
          <Route path={ROUTE_URL.USER_INFO} element={<Information />} />
          <Route path={ROUTE_URL.SOCIA_MEDIA} element={<SocialMedia />} />
          <Route path={ROUTE_URL.REDEEM} element={<ConfirmationModal />} />
          <Route path={ROUTE_URL.SUCCESS} element={<Success />} />
          <Route path={ROUTE_URL.THANK_YOU} element={<ThankYou />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </MainContextDataLayer>
      <ToastContainer />
    </Layout>
  );
}

export default App;
