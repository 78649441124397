import questionsAPIs from "../services";
import {
  addFeedbackError,
  addFeedbackRequest,
  addFeedbackSuccess,
  checkRewardStatusError,
  checkRewardStatusRequest,
  checkRewardStatusSuccess,
  designLanguageError,
  designLanguageRequest,
  designLanguageSuccess,
  getQuestionError,
  getQuestionRequest,
  getQuestionSuccess,
  productSearchError,
  productSearchRequest,
  productSearchSuccess,
  redeemRewardError,
  redeemRewardRequest,
  redeemRewardSuccess,
  serviceSearchError,
  serviceSearchRequest,
  serviceSearchSuccess,
} from "../slices/slice";

export const getQuestiostypeApi = (brand, code) => {
  return (dispatch) => {
    dispatch(getQuestionRequest());
    questionsAPIs
      .getQuestions(brand, code)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(getQuestionSuccess(data));
        } else {
          dispatch(getQuestionError(error));
        }
      })
      .catch((error) => {
        dispatch(getQuestionError(error));
      });
  };
};

export const addFeedbackApi = (data) => {
  return (dispatch) => {
    dispatch(addFeedbackRequest());
    questionsAPIs
      .addFeedback(data)
      .then((response) => {
        const { data, message } = response;
        dispatch(addFeedbackSuccess({ data, message }));
      })
      .catch((error) => {
        dispatch(addFeedbackError(error));
      });
  };
};

export const checkRewardStatus = (code) => {
  return (dispatch) => {
    dispatch(checkRewardStatusRequest());
    questionsAPIs
      .checkRewardStatus(code)
      .then((response) => {
        const { data, status, error, message } = response.data;
        if (status === 1 || status === 2) {
          dispatch(checkRewardStatusSuccess({ data, message, status }));
        } else {
          dispatch(checkRewardStatusError(error));
        }
      })
      .catch((error) => {
        dispatch(checkRewardStatusError(error));
      });
  };
};

export const redeemReward = (code, is_redeem) => {
  return (dispatch) => {
    dispatch(redeemRewardRequest());
    questionsAPIs
      .redeemReward(code, is_redeem)
      .then((response) => {
        const { status, error, message } = response.data;
        if (status === 1) {
          dispatch(redeemRewardSuccess(message));
        } else {
          dispatch(redeemRewardError(error));
        }
      })
      .catch((error) => {
        dispatch(redeemRewardError(error));
      });
  };
};

export const searchProduct = (branch_id, brand_id, payload) => {
  return (dispatch) => {
    dispatch(productSearchRequest());
    questionsAPIs
      .productSearch(branch_id, brand_id, payload)
      .then((response) => {
        const { status, error, message, data } = response.data;
        if (status === 1) {
          dispatch(productSearchSuccess(data));
        } else {
          dispatch(productSearchError(error));
        }
      })
      .catch((error) => {
        dispatch(productSearchError(error));
      });
  };
};

export const searchService = (branch_id, brand_id, payload) => {
  return (dispatch) => {
    dispatch(serviceSearchRequest());
    questionsAPIs
      .serviceSearch(branch_id, brand_id, payload)
      .then((response) => {
        const { status, error, message, data } = response.data;
        if (status === 1) {
          dispatch(serviceSearchSuccess(data));
        } else {
          dispatch(serviceSearchError(error));
        }
      })
      .catch((error) => {
        dispatch(serviceSearchError(error));
      });
  };
};

export const allDesignLanguages = () => {
  return (dispatch) => {
    dispatch(designLanguageRequest());
    questionsAPIs
      .getLanguages()
      .then((response) => {
        const { status, error, data } = response.data;
        if (status === 1) {
          dispatch(designLanguageSuccess(data));
        } else {
          dispatch(designLanguageSuccess(error));
        }
      })
      .catch((error) => {
        dispatch(designLanguageError(error));
      });
  };
};
