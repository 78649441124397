import React, { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_URL from "../../config/routes";
import { checkRewardStatus, redeemReward } from "../../middlewares/questions";
import Invalid from "./Invalid";

const ConfirmationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    rewardStatus,
    rewardLoading,
    rewardMessage,
    rewardStatusMessage,
    rewardCheckAvailability,
    rewardStatusloading,
  } = useSelector((state) => state.question);
  let { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      dispatch(checkRewardStatus(code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (!rewardLoading && rewardMessage) {
      navigate(ROUTE_URL.SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardMessage]);

  const handleclick = () => {
    let is_redeem = true;
    dispatch(redeemReward(code, is_redeem));
    navigate(ROUTE_URL.SUCCESS);
  };

  const handleCancel = () => {
    navigate(ROUTE_URL.THANK_YOU);
  };

  return (
    <>
      {rewardStatus && rewardStatus !== null ? (
        <div className="redeemVoucher">
          <div className="code-border">
            <>
              {rewardStatusloading ? (
                <Spinner
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20% auto 0",
                    color: "#164665",
                  }}
                  animation="border"
                />
              ) : (
                <>
                  <h6>{[t("ARE_YOU_SURE")]}</h6>
                  {rewardCheckAvailability === 2 ? (
                    <p style={{ color: "#f0ad4e" }}>{rewardStatusMessage}</p>
                  ) : null}
                  <div className="Experience-btn-modal d-flex justify-content-center">
                    <Button
                      type="button"
                      className="save-button"
                      onClick={() => handleclick("save")}
                      disabled={rewardCheckAvailability === 2 ? true : false}
                    >
                      {t("AGREE")}
                    </Button>
                    <Button
                      className="btn cancel-button"
                      onClick={() => handleCancel()}
                      disabled={rewardCheckAvailability === 2 ? true : false}
                    >
                      {t("DISAGREE")}
                    </Button>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      ) : (
        <Invalid />
      )}
    </>
  );
};

export default ConfirmationModal;
