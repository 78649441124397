import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Google from "../assets/images/Google.svg";
import insta from "../assets/images/instagram.svg";
import fb from "../assets/images/facebook.svg";
import { useDispatch, useSelector } from "react-redux";
import { addFeedbackApi, getQuestiostypeApi } from "../middlewares/questions";
import IndexedDBServices from "../services/indexedDB-services";
import {
  OBJECT_STORE_ANSWERS,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";
import { t } from "i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { clearAnswers } from "../slices/slice";
import { toast } from "react-toastify";
import { getActiveElement } from "formik";

const SocialMedia = () => {
  const {
    getQuestionloading,
    getQuestion,
    getAllQuestions,
    answers,
    ratingOption,
    addfeedback,
    message,
    addfeedbackloading,
    addfeedbackerror,
  } = useSelector((state) => state.question);
  const nav = useNavigate();
  const { code, brand } = useParams();
  const dispatch = useDispatch();
  const [custEmail, setCustEmail] = useState("");
  const [overallExperience, setOverallExperience] = useState("");
  const buttonColor = localStorage.getItem("button-color");

  function ToastSuccess(message = "Success", optionalConfig = {}) {
    toast.success(message, {
      style: { marginLeft: "25px" },
      ...optionalConfig,
    });
  }

  function ToastError(message = "Error!", optionalConfig = {}) {
    toast.error(message, {
      style: { marginLeft: "25px" },
      ...optionalConfig,
    });
  }

  function ToastWarning(message = "Alert!", optionalConfig = {}) {
    toast.warn(message, { style: { marginLeft: "25px" }, ...optionalConfig });
  }

  useEffect(() => {
    dispatch(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IndexedDBServices.addFeedbackAnswers(answers[answers.length - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustEmail(answers[answers.length - 1]?.customer_email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionloading]);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const tx = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
      const allData = tx.objectStore(OBJECT_STORE_ANSWERS);
      const all = allData.getAll();
      all.onsuccess = (query) => {
        let feedbackData = query.srcElement.result;
        const feedback = [];

        const category_type_answer =
          answers &&
          answers.length > 0 &&
          answers.filter((o) => o.id === getAllQuestions[0]?._id);
        const findCat =
          category_type_answer &&
          category_type_answer.length > 0 &&
          category_type_answer[0]?.main.find(
            (o) => o.name.en === "Overall Experience"
          );
        setOverallExperience(findCat);

        const category_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.id === getAllQuestions[0]?._id);
        if (category_type.length > 0) {
          let final = category_type[0]?.main[0];

          const category_data = {
            question: final?.questionName,
            question_id: category_type[0]?.id,
            question_type: final?.type,
            sequence: final?.sequence,
            answer: {
              category: [],
            },
          };
          const findCat =
            category_type &&
            category_type.length > 0 &&
            category_type[0]?.main.find(
              (o) => o.name.en === "Overall Experience"
            );
          setOverallExperience(findCat);
          category_type &&
            category_type.length > 0 &&
            category_type[0]?.main.map((o) =>
              category_data.answer?.category.push({
                category_name: o.name,
                question_category_id: o.id,
                ratings: o.rating,
                colour: o.colour,
              })
            );
          feedback.push(category_data);
        }

        const single_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.type === "single");
        if (single_type.length > 0) {
          single_type &&
            single_type.length > 0 &&
            single_type.map((o) => {
              const single_type_data = {
                question: o.question,
                question_type: o.type,
                question_id: o.id,
                sequence: o.sequence,
                answer: {
                  opinion: o.opinion.trim(),
                },
              };
              feedback.push(single_type_data);
              return o;
            });
        }

        const multiple_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.question_type === "multiple");
        if (multiple_type) {
          multiple_type.length > 0 &&
            multiple_type.map((o) => {
              return feedback.push(o);
            });
        }

        const staff_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.type === "staff");
        if (staff_type.length > 0) {
          const staff_data = {
            question: staff_type[0].question,
            question_type: staff_type[0].type,
            sequence: staff_type[0].sequence,
            question_id: staff_type[0].question_id,
            answer: {
              staff: [],
            },
          };

          staff_type &&
            staff_type.length > 0 &&
            staff_type.map((o) => {
              staff_data.answer.staff.push({
                staff_id: o.id,
                ratings: o.rating,
                opinion: o.opinion.trim(),
                name: o.name,
                profile_pic: o.image,
              });
              return o;
            });
          feedback.push(staff_data);
        }

        const product_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.type === "product");
        if (product_type.length > 0) {
          const product_data = {
            question: product_type[0].question,
            question_type: product_type[0].type,
            sequence: product_type[0].sequence,
            question_id: product_type[0].question_id,
            answer: {
              product: [],
            },
          };

          product_type &&
            product_type.length > 0 &&
            product_type.map((o) => {
              product_data.answer.product.push({
                product_id: o.id,
                ratings: o.rating,
                opinion: o.opinion.trim(),
                name: o.name,
                logo: o.image,
              });
              return o;
            });
          feedback.push(product_data);
        }
        const service_type =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.filter((o) => o.type === "service");
        if (service_type.length > 0) {
          const service_data = {
            question: service_type[0].question,
            question_type: service_type[0].type,
            sequence: service_type[0].sequence,
            question_id: service_type[0].question_id,
            answer: {
              service: [],
            },
          };

          service_type &&
            service_type.length > 0 &&
            service_type.map((o) => {
              service_data.answer.service.push({
                service_id: o.id,
                ratings: o.rating,
                opinion: o.opinion.trim(),
                name: o.name,
                logo: o.image,
              });
              return o;
            });
          feedback.push(service_data);
        }

        const user_info =
          feedbackData &&
          feedbackData.length > 0 &&
          feedbackData.find((o) => o.id === getQuestion?._id);
        const payload = {
          feed_back: feedback,
          customer_email: user_info.customer_email,
          customer_name: user_info.customer_name,
          customer_phone: user_info.customer_phone,
          branch_id: user_info.branch_id,
          brand_id: user_info.brand_id,
          experience_type_id: user_info.experience_type_id,
          share_customer_data:
            ratingOption && ratingOption?.customer_data === "Anonymous"
              ? false
              : user_info.share_customer_data,
          anonymous_customer:
            ratingOption && ratingOption?.customer_data === "Anonymous"
              ? true
              : user_info.share_customer_data === false
                ? true
                : false,
          survey_id: user_info.survey_id,
        };
        dispatch(addFeedbackApi(payload));
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!addfeedbackloading && message) {
      if (addfeedback.status === 1) {
        ToastSuccess(message);
      } else if (addfeedback.status === 2) {
        ToastWarning(message);
      }
    } else if (addfeedbackerror) {
      if (answers.length === 0) {
        nav(`/${brand}/${code}/reward`);
      } else {
        // ToastError("Failed to Submit Feedback");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, addfeedbackerror]);

  setTimeout(() => {
    if (addfeedback && (addfeedback.status === 1 || addfeedback.status === 2)) {
      IndexedDBServices.clearObjecrtStoreData();
      dispatch(clearAnswers());
    }
  }, 2000);

  return (
    <div className="PCresponse">
      {getQuestionloading ? (
        <Spinner
          style={{
            color: `${buttonColor ? buttonColor : "#164665"}`,
          }}
          animation="border"
        />
      ) : (
        <div className="paddingApp Followcontainer">
          <div className="FollowInfo">
            <div className="BrandFollow">
              <div className="NavLogo">
                {getQuestion?.brand_design?.show_logo ? (
                  <img
                    src={
                      `${process.env.REACT_APP_BASE_URL}${getQuestion?.brand_detail?.logo}` ||
                      require("../assets/images/BrandLogo.png")
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://api.app.trustree.com/images/Brandlogo.png";
                    }}
                    alt="logo"
                    loading="lazy"
                  />
                ) : null}
              </div>
              {addfeedbackerror ? (
                <div style={{ color: "red" }}>{t("FAILED_TO_SUBMIT")}!</div>
              ) : getQuestion?.rewards ? (
                <>
                  <h6>{t("YOUR_GIFT_SENT_TO_EMAIL")}</h6>
                  <h6>{custEmail ? custEmail : ""}</h6>
                </>
              ) : (
                <h6>{t("THANK_YOU")}</h6>
              )}
            </div>
            {
              (getQuestion && getQuestion.branch) ?
                getQuestion?.branch?.map((o, idx) => {
                  const link = o.google_link
                  const fullLink = link
                    ? link.startsWith("http")
                      ? link
                      : `https://${link}`
                    : "";
                  if (fullLink !== "") {
                    return (
                      <ul className="information_Wpr" key={idx}>
                        <li>
                          <p>
                            <strong>{t("DO_US_A_FAVOR")}</strong>
                            <br /> {t("AND_SHARE_YOUR")} <strong>{t("STARS")}</strong>{" "}
                            {t("RATING_ON_GOOGLE")}
                          </p>
                        </li>
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          target="_blank"
                          to={fullLink}
                        >
                          <li className="reviweLeave">
                            <h4>{t("LEAVE_REVIEW")}</h4>
                            <div>
                              <img alt="" src={Google} loading="lazy" />
                            </div>
                          </li>
                        </Link>
                      </ul>
                    );
                  } else {
                    return null;
                  }
                })
                : null
            }
            <div className="FollowSection">
              {(getQuestion.social_media?.is_facebook_on === true ||
                getQuestion.social_media?.is_instagram_on === true) && (
                  <h6>{t("FOLLOW_US")}</h6>
                )}
              <div className="followImg">
                {getQuestion &&
                  getQuestion.social_media?.is_facebook_on === true ? (
                  <Link
                    target="_blank"
                    to={`${getQuestion.social_media?.facbook_link}`}
                  >
                    <img alt="facebook" src={fb} loading="lazy" />
                  </Link>
                ) : null}
                {getQuestion &&
                  getQuestion.social_media?.is_instagram_on === true ? (
                  <Link
                    target="_blank"
                    to={`${getQuestion.social_media?.instagram_link}`}
                  >
                    <img alt="instagram" src={insta} loading="lazy" />
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default SocialMedia;
