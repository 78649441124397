import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import BackArrow from "./BackArrow";
import Footer from "./Footer";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestiostypeApi } from "../middlewares/questions";
import AllImages from "./AllImages";
import * as Yup from "yup";
import { addAnswers } from "../slices/slice";
import {
  OBJECT_STORE_ANSWERS,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";
import { useTranslation } from "react-i18next";
import ButtonComponent from "./ButtonComponent";
import IndexedDBServices from "../services/indexedDB-services";

function SpecificRating() {
  const { t } = useTranslation();
  const params = useParams();
  const { code, brand, question_id } = useParams();
  const nav = useNavigate();
  const type = params.type;
  const paramsId = params.id;
  const page = parseInt(params.page);
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const buttonColor = localStorage.getItem("button-color");

  const {
    getAllQuestions,
    getQuestionloading,
    getStaffDetails,
    getProductDetails,
    getServiceDetails,
    answers,
    getQuestion,
  } = useSelector((state) => state.question);
  const dispath = useDispatch();

  useEffect(() => {
    dispath(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [findRating, setFindRating] = useState();
  const [names, setNames] = useState();
  const [logos, setLogos] = useState();

  const findQuestionName =
    getAllQuestions &&
    getAllQuestions.length > 0 &&
    getAllQuestions.find((o) => o.type === type);

  useEffect(() => {
    if (question_id !== findQuestionName?._id) {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "staff") {
      const staffName =
        getStaffDetails &&
        getStaffDetails.length > 0 &&
        getStaffDetails.find((o) => o?._id === paramsId);
      setNames(staffName?.name);
      setLogos(staffName?.profile_pic);
    }
    if (type === "product") {
      const productList =
        getProductDetails &&
        getProductDetails.length > 0 &&
        getProductDetails.flatMap((o) => {
          return o.products;
        });
      const productName =
        productList &&
        productList.length > 0 &&
        productList.find((o) => o?._id === paramsId);
      setNames(productName?.name);
      setLogos(productName?.logo);
    }
    if (type === "service") {
      const serviceList =
        getServiceDetails &&
        getServiceDetails.length > 0 &&
        getServiceDetails.flatMap((o) => {
          return o.services;
        });
      const serviceName =
        serviceList &&
        serviceList.length > 0 &&
        serviceList.find((o) => o?._id === paramsId);
      setNames(serviceName?.name);
      setLogos(serviceName?.image);
    }
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_ANSWERS)) {
        nav(`/${params.brand}/${params.code}/reward`);
      } else {
        const tx = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
        const allData = tx.objectStore(OBJECT_STORE_ANSWERS);
        const all = allData.getAll();
        all.onsuccess = (query) => {
          let answerData = query.srcElement.result;
          let checkType =
            answerData &&
            answerData.length > 0 &&
            answerData.filter((o) => o.id === paramsId);

          let check =
            checkType &&
            checkType.length > 0 &&
            checkType.find((o) => o.id === paramsId);
          setFindRating(check);

          if (check && check.id === paramsId) {
            formik.setValues({
              opinion: check.opinion,
              rating: check.rating,
              id: paramsId,
              question: findQuestionName?.name,
              question_id: findQuestionName?._id,
              type: type,
              name: names,
              image: logos,
              sequence: findQuestionName?.sequence,
            });
          } else {
            formik.setValues({
              opinion: "",
              rating: givenRating,
              id: paramsId,
              question: findQuestionName?.name,
              question_id: findQuestionName?._id,
              type: type,
              name: names,
              image: logos,
              sequence: findQuestionName?.sequence,
            });
          }
        };
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStaffDetails, getProductDetails, getServiceDetails]);

  const [givenRating] = useState("");

  const handleRating = (rate = Number) => {
    formik.setValues({
      opinion: formik.values.opinion,
      rating: rate,
      id: paramsId,
      question: findQuestionName?.name,
      question_id: findQuestionName?._id,
      type: type,
      name: names,
      image: logos,
      sequence: findQuestionName?.sequence,
    });
  };

  const handleClickNext = (values) => {
    formik.setValues({
      opinion: values.opinion,
      rating: givenRating,
      id: paramsId,
      question: findQuestionName?.name,
      question_id: findQuestionName?._id,
      type: type,
      name: names,
      image: logos,
      sequence: findQuestionName?.sequence,
    });
    dispath(addAnswers(values));

    let questionLength = getAllQuestions.length;
    let page = parseInt(params.page);
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      opinion: "",
      rating: "",
      id: paramsId,
      question: findQuestionName?.name,
      question_id: findQuestionName?._id,
      type: type,
      name: names,
      image: logos,
      sequence: findQuestionName?.sequence,
    },
    validationSchema: Yup.object({
      rating: Yup.string().required(),
    }),
    onSubmit: (values) => handleClickNext(values),
  });

  const handleRateOneMore = () => {
    formik.setValues({
      opinion: formik.values.opinion,
      rating: givenRating,
      id: paramsId,
      question: findQuestionName?.name,
      question_id: findQuestionName?._id,
      type: type,
      name: names,
      image: logos,
      sequence: findQuestionName?.sequence,
    });
    dispath(addAnswers(formik.values));
    let page = parseInt(params.page);
    nav(`/${params.brand}/${params.code}/${page}/${question_id}`);
  };
  return (
    <div className="PCresponse">
      {getQuestionloading ? (
        <Spinner
          style={{
            color: `${buttonColor ? buttonColor : "#164665"}`,
          }}
          animation="border"
        />
      ) : (
        <>
          <BackArrow data={getQuestion} />
          <div className="rateWprContainer">
            <h6 className="globalText">
              {findQuestionName?.name?.[t("language")]
                ? findQuestionName?.name?.[t("language")]
                : findQuestionName?.name?.[t("en")]}
            </h6>
            <ul className="mx-2">
              <li>
                {type === "staff" ? (
                  getStaffDetails &&
                  getStaffDetails.length > 0 &&
                  getStaffDetails.map((staff, i) => {
                    return (
                      <div key={i}>
                        {staff._id === paramsId ? (
                          staff.profile_pic || !staff.profile_pic ? (
                            <div>
                              <AllImages
                                logo={staff.profile_pic}
                                id={staff._id}
                              />
                              <p>
                                {staff.name?.[t("language")]
                                  ? staff.name?.[t("language")]
                                  : staff.name?.[t("en")]}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <AllImages
                                logo={staff.profile_pic}
                                id={staff._id}
                              />
                              <p>
                                {staff.name?.[t("language")]
                                  ? staff.name?.[t("language")]
                                  : staff.name?.[t("en")]}
                              </p>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })
                ) : type === "product" ? (
                  getProductDetails &&
                  getProductDetails.length > 0 &&
                  getProductDetails.map((data) => {
                    return data.products.map((product, i) => {
                      return (
                        <div key={i}>
                          {product._id === paramsId ? (
                            product.logo === null ? (
                              <div>
                                <AllImages
                                  logo={product.logo}
                                  id={product._id}
                                />
                                <p>
                                  {product.name?.[t("language")]
                                    ? product.name?.[t("language")]
                                    : product.name?.[t("en")]}
                                </p>
                              </div>
                            ) : (
                              <div>
                                <AllImages
                                  logo={product.logo}
                                  id={product._id}
                                />
                                <p>
                                  {product.name?.[t("language")]
                                    ? product.name?.[t("language")]
                                    : product.name?.[t("en")]}
                                </p>
                              </div>
                            )
                          ) : null}
                        </div>
                      );
                    });
                  })
                ) : type === "service" ? (
                  getServiceDetails &&
                  getServiceDetails.length > 0 &&
                  getServiceDetails.map((data, i) => {
                    return data.services.map((service, i) => {
                      return (
                        <div key={i}>
                          {service._id === paramsId ? (
                            service.image === null ? (
                              <div>
                                <AllImages
                                  logo={service.image}
                                  id={service._id}
                                />
                                <p>
                                  {service.name?.[t("language")]
                                    ? service.name?.[t("language")]
                                    : service.name?.[t("en")]}
                                </p>
                              </div>
                            ) : (
                              <div>
                                <AllImages
                                  logo={service.image}
                                  id={service._id}
                                />
                                <p>
                                  {service.name?.[t("language")]
                                    ? service.name?.[t("language")]
                                    : service.name?.[t("en")]}
                                </p>
                              </div>
                            )
                          ) : null}
                        </div>
                      );
                    });
                  })
                ) : (
                  <>{t("DATA_NOT_FOUND")}</>
                )}
              </li>
              <li>
                <Rating
                  onClick={handleRating}
                  transition
                  ratingValue={formik.values.rating}
                  rtl={
                    selectedLanguage === "ar" ||
                    selectedLanguage === "fa" ||
                    selectedLanguage === "he" ||
                    selectedLanguage === "ur"
                      ? true
                      : false
                  }
                  initialValue={
                    answers[page]
                      ? findRating && findRating?.rating
                      : formik.values?.rating
                  }
                />
              </li>
            </ul>
            <form onSubmit={formik.handleSubmit}>
              <div className="paddingTextarea">
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder={t("SHARE_YOUR_OPINION_HERE")}
                  {...formik.getFieldProps("opinion")}
                />
              </div>
              <div className="Nestbtn mt-5">
                <ButtonComponent
                  buttonDisabled={!(formik.isValid && formik.dirty)}
                  buttonType={"submit"}
                  props={getQuestion}
                  buttonLabel={[t("NEXT")]}
                />
                {getAllQuestions &&
                  getAllQuestions.map((k, i) => {
                    return k.choice === "multiple" && k.type === type ? (
                      <div key={i} style={{ marginTop: "24px" }}>
                        <Button
                          className="btn btn-outline-primary"
                          onClick={handleRateOneMore}
                        >
                          {t("RATE_ONE_MORE")}
                        </Button>
                      </div>
                    ) : null;
                  })}
              </div>
            </form>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default SpecificRating;
