import {
  CATEGORY_RATING,
  OBJECT_STORE_ANSWERS,
  STORE_CURRENT_TIME,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";

export const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB;

const checkIndexedDB = () => {
  if (!idb) {
    window.alert("Your Browser dosen't support indexedDB");
    return;
  }
  const request = idb.open(STORE_NAME, STORE_VERSION);

  request.onupgradeneeded = (event) => {
    const db = request.result;
    if (!db.objectStoreNames.contains(OBJECT_STORE_ANSWERS)) {
      db.createObjectStore(OBJECT_STORE_ANSWERS, { keyPath: "id" });
    }
    if (!db.objectStoreNames.contains(CATEGORY_RATING)) {
      db.createObjectStore(CATEGORY_RATING, { keyPath: "id" });
    }
    if (!db.objectStoreNames.contains(STORE_CURRENT_TIME)) {
      db.createObjectStore(STORE_CURRENT_TIME, { keyPath: "id" });
    }
    const currentTime = { id: 1, time: new Date().getTime() };
    storeCurrentTime(currentTime);
  };
};

const addFeedbackAnswers = (currentTime) => {
  const dbPromise = idb.open(STORE_NAME, STORE_VERSION);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    const tx = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
    const feedbackData = tx.objectStore(OBJECT_STORE_ANSWERS);
    const feedback = feedbackData.put(currentTime);
    feedback.onsuccess = () => {
      tx.oncomplete = () => {
        db.close();
      };
    };
  };
};

const addCategoryAnswers = (values) => {
  const dbPromise = idb.open(STORE_NAME, STORE_VERSION);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    const tx = db.transaction(CATEGORY_RATING, "readwrite");
    const categoryData = tx.objectStore(CATEGORY_RATING);
    const cat = categoryData.put(values);
    cat.onsuccess = () => {
      tx.oncomplete = () => {
        db.close();
      };
    };
  };
};

const storeCurrentTime = (currentTime) => {
  const dbPromise = idb.open(STORE_NAME, STORE_VERSION);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (db.objectStoreNames.contains(STORE_CURRENT_TIME)) {
      const tx = db.transaction(STORE_CURRENT_TIME, "readwrite");
      const current_time_data = tx.objectStore(STORE_CURRENT_TIME);
      const time = current_time_data.add(currentTime);
      time.onsuccess = () => {
        tx.oncomplete = () => {
          db.close();
        };
      };
    }
  };
};

const clearObjecrtStoreData = () => {
  const dbPromise = idb.open(STORE_NAME, STORE_VERSION);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    const tx = db.transaction(STORE_CURRENT_TIME, "readwrite");
    const clear_current_time_data = tx.objectStore(STORE_CURRENT_TIME);
    clear_current_time_data.clear(STORE_CURRENT_TIME);

    const tx1 = db.transaction(CATEGORY_RATING, "readwrite");
    const clear_category_rating = tx1.objectStore(CATEGORY_RATING);
    clear_category_rating.clear(CATEGORY_RATING);

    const tx2 = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
    const clear_answers = tx2.objectStore(OBJECT_STORE_ANSWERS);
    clear_answers.clear(OBJECT_STORE_ANSWERS);

    db.close();
  };
};

const clearStoreData = () => {
  const dbPromise = idb.open(STORE_NAME, STORE_VERSION);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;

    const tx1 = db.transaction(CATEGORY_RATING, "readwrite");
    const clear_category_rating = tx1.objectStore(CATEGORY_RATING);
    clear_category_rating.clear(CATEGORY_RATING);

    const tx2 = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
    const clear_answers = tx2.objectStore(OBJECT_STORE_ANSWERS);
    clear_answers.clear(OBJECT_STORE_ANSWERS);

    db.close();
  };
};

const DeleteDB = () => {
  var req = indexedDB.deleteDatabase(STORE_NAME, STORE_VERSION);
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log(
      "Couldn't delete database due to the operation being blocked",
      req
    );
  };
};

const checkDBdata = (params, nav) => {
  const dbData = indexedDB.open(STORE_NAME, STORE_VERSION);
  dbData.onsuccess = () => {
    const db = dbData.result;
    if (!db.objectStoreNames.contains(CATEGORY_RATING)) {
      nav(`/${params.brand}/${params.code}/reward`);
    } else {
      const tx = db.transaction(CATEGORY_RATING, "readwrite");
      const allData = tx.objectStore(CATEGORY_RATING);
      const all = allData.getAll();
      all.onsuccess = (query) => {
        if (!query.srcElement.result?.length) {
          nav(`/${params.brand}/${params.code}/reward`);
        }
      };
    }
  };
};

const IndexedDBServices = {
  checkIndexedDB,
  addFeedbackAnswers,
  addCategoryAnswers,
  storeCurrentTime,
  clearObjecrtStoreData,
  clearStoreData,
  checkDBdata,
  DeleteDB,
};

export default IndexedDBServices;
