import { createContext, useContext, useMemo, useReducer } from "react";

export const MainContext = createContext();

export const MainContextDataLayer = ({ intialState, reducer, children }) => {
  const [state, dispatch] = useReducer(reducer, intialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return (
    <MainContext.Provider value={value}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContextDataLayerValue = () => useContext(MainContext);
