import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "../../../components/landing/NavBar";
import Footer from "../../../components/landing/Footer";
import ScrollingFeatureText from "../../../components/landing/ScrollingFeatureText";
import CWCaseStudyImage from "./../../../assets/images/landing/cwcasestudy.svg";
import HeroArrow from "./../../../assets/images/landing/heroarrow.svg";
import DemoArrow from "./../../../assets/images/landing/demoarrow.svg";
import DemoQR from "./../../../assets/images/landing/demoqr.svg";
import MoreArrowDown from "./../../../assets/images/landing/morearrowdown.svg";
import Logo from "./../../../assets/images/landing/trustreeslogo.jpeg";

import { useEffect, useState } from "react";

const ChickinWorxCaseStudyBlogPostPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    setIsLoading(false);
  }, []);

  return (
    <div className="bg-[#FFFFFF]">
      <NavBar></NavBar>
      <section id="blog">
        <div className="w-full h-full desktop:!pt-20 mobile:!pt-28 !pb-24 desktop:!px-20 mobile:!px-5">
          <div
            class={`flex justify-center items-center ${
              isLoading ? "block" : "hidden"
            }`}
          >
            <div
              class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div
            className={`bg-white rounded-2xl w-full !p-4 !mt-16 ${
              isLoading ? "hidden" : "block"
            }`}
          >
            <div className="flex flex-col gap-6 w-full h-full">
              <div className="w-full h-full">
                <iframe
                  className="w-full h-[400px] max-h-[550px]"
                  src="https://www.youtube.com/embed/XyBJfQnahIA?si=8cCl6lOy7WSzrUq8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="w-full h-full relative">
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-bold text-2xl !mt-4">
                  {t(
                    "How did Chickin Worx use Trustree to create a a cult of Worxers?"
                  )}
                </div>
                <div className="flex desktop:flex-row mobile:flex-col desktop:items-center mobile:items-start justify-start desktop:gap-14 mobile:gap-3 !mt-2">
                  <div className="flex items-center gap-4">
                    <div className="">
                      <div className="relative">
                      {/* <div className="relative w-12 h-w-12"> */}
                        <div className="">
                          {/* <div className="flex rounded-full border border-gray-100 shadow-sm"> */}
                          <img className="w-8 h-8 rounded-full" src={Logo} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="">
                        <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-base">
                          Trustree
                        </div>
                      </div>
                      <div className="">
                        <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-medium text-sm">
                          Trustree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="whitespace-pre-line text-[#00000080] font-SpaceGrotesk font-normal text-sm">
                    Mon, 6 February 2023 • NEWS & UPDATES
                  </div>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  {t(
                    "Chickin Worx was able to use Trustree to become one of the most customer centric businesses in the Middle East. They are currently the most highly rated restaurant in Egypt on Google. The management at Chickin Worx really understands the importance of putting the customer at the center of the main stage. Chickin Worx pin points Trustree as a main tool and instrument that helped them raise their customer experience standards tremendously."
                  )}
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  <span className="font-bold">
                    {t("What is the story behind Chickin Worx?")}
                  </span>
                  <br></br>
                  <span>
                    {t(
                      "I started the restaurant a few years ago after coming back from the US. I was always a fried chicken lover, but i never found the quality in Egypt satisfying. I found this complaint very common so i decided to start Chickin Worx. However, at the core of the complaints i've heard, customer service was always at the heart of the matter. Therefore, when we started Chickin Worx we committed to giving the best possible experience for our customers. There was always a gap in quality of product and service. That's why we started Chickin Worx."
                    )}
                  </span>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  <span className="font-bold">
                    {t("What is the reason Chickin Worx succeeded?")}
                  </span>
                  <br></br>
                  <span>
                    {t(
                      "It's always Day 1 as Jeff Bezos says. We're doing pretty well, but still don't consider ourselves a success. We still still have a lot ahead of us. We don't take our customers for granted. We don't believe that our product or menu is the only thing we serve. We understand that our customers are looking for a wholistic experience. We always try our best to make sure we pass a lot of multi-dimensional values to our customer."
                    )}
                  </span>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  <span className="font-bold">
                    {t("What makes a great customer experience?")}
                  </span>
                  <br></br>
                  <span>
                    {t(
                      "It's one where we exceed the emotional expectations of the customer. We're emotional creatures and it's very important that the customer leaves happier than they've arrived. Therefore, even if we have the best food ever we don't want to cause inconvenience to the customer. We don't want the customers to come and feel like we've burdened them with a longer wait times, lower food quality, below average customer service. If we get all these things right we'd be great at serving our customer's emotions which should be the primary purpose we exist."
                    )}
                  </span>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  <span className="font-bold">
                    {t(
                      "How has Trustree helped you improve the customer's experience?"
                    )}
                  </span>
                  <br></br>
                  <span>
                    {t(
                      "If you can't hear your customer's voice then you'll never be able to improve their experience. Trustree has given us the ability to listen to our customers first hand and gives them the space to talk to us directly without any intermediaries. We've also been able to create an incentive system in each restaurant through Trustree. The restaurant's management is motivated and incentivized to get higher ratings every month for a bonus and we now measure our success metrics through the Customer's Voice on Trustree."
                    )}
                  </span>
                </div>
                <div className="whitespace-pre-line text-black font-SpaceGrotesk font-normal text-lg !mt-4">
                  <span className="font-bold">
                    {t(
                      "Can you give an example of a feedback which you've improved?"
                    )}
                  </span>
                  <br></br>
                  <span>
                    {t(
                      "At one point we've started realizing that there was a growing complaint or suggestion from customers that our service time was quite slow. Therefore, we started focusing a lot more on speed of service and making sure we're serving the customers faster. We've actually added a Service Time category to let customers rate us and give us feedback specifically on it. Now, we have accurate data on how fast we are according to our customers. Also, when we launched an item before we started realizing that customers were complaining about the taste of one of the components in it. Therefore, we completely changed it and it's now one of our best sellers."
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default ChickinWorxCaseStudyBlogPostPage;
