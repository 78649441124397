import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  OBJECT_STORE_ANSWERS,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";
import IndexedDBServices from "../services/indexedDB-services";
import { addAnswers } from "../slices/slice";
import ButtonComponent from "./ButtonComponent";
import * as Yup from "yup";

function SingleTypeQuestion(props) {
  const { getQuestion, getAllQuestions } = useSelector(
    (state) => state.question
  );
  const { t } = useTranslation();
  const { answers } = useSelector((state) => state.question);
  const params = useParams();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const page = parseInt(params.page);
  let questionLength = props.data.length;
  const [ratingName, setRatingName] = useState();

  useEffect(() => {
    if (params.question_id !== props.object._id) {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    IndexedDBServices.checkDBdata(params, nav);

    const questionIndex = answers?.findIndex(
      (o) => o?.id === getAllQuestions[page - 2]?._id
    );
    IndexedDBServices.addFeedbackAnswers(
      answers[
        questionIndex && questionIndex > 0 ? questionIndex : answers?.length - 1
      ]
    );
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_ANSWERS)) {
        nav(`/${params.brand}/${params.code}/reward`);
      } else {
        const tx = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
        const allData = tx.objectStore(OBJECT_STORE_ANSWERS);
        const all = allData.getAll();
        all.onsuccess = (query) => {
          let answerData = query.srcElement.result;
          let checkType =
            answerData &&
            answerData.length > 0 &&
            answerData.filter((o) => o.type === "single");

          let check =
            checkType &&
            checkType.length > 0 &&
            checkType.find((o) => o.id === props.object._id);

          if (check && check.id === props.object._id) {
            formik.setValues({
              id: props.object._id,
              type: props.object.type,
              question: props.object.name,
              opinion: check.opinion,
              sequence: props.object.sequence,
            });
          } else {
            formik.setValues({
              id: props.object._id,
              type: props.object.type,
              question: props.object.name,
              opinion: "",
              sequence: props.object.sequence,
            });
          }
        };
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object]);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const tx = db.transaction("category_rating", "readwrite");
      const categoryData = tx.objectStore("category_rating");
      const cat = categoryData.getAll();
      cat.onsuccess = (query) => {
        let data = query.srcElement.result;
        let sortRatingAsec =
          data &&
          data.length > 0 &&
          data[0].main.sort((a, b) => {
            let rating1 = a.rating;
            let rating2 = b.rating;
            if (rating1 < rating2) {
              return -1;
            }
            if (rating1 > rating2) {
              return 1;
            }
            return 0;
          });
        if (props.object.sequence === 1) {
          let lowest =
            sortRatingAsec && sortRatingAsec.length > 0 && sortRatingAsec[0];
          setRatingName(
            lowest?.name?.[t("language")]
              ? lowest?.name?.[t("language")]
              : lowest?.name?.[t("en")]
          );
        } else if (props.object.sequence === 2) {
          let highest =
            sortRatingAsec &&
            sortRatingAsec.length > 0 &&
            sortRatingAsec[sortRatingAsec.length - 1];
          setRatingName(
            highest?.name?.[t("language")]
              ? highest?.name?.[t("language")]
              : highest?.name?.[t("en")]
          );
        }
      };
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object]);

  const handleClickNext = (values) => {
    dispatch(addAnswers(values));
    formik.resetForm();
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };

  const singleTypeSchema = Yup.object({
    opinion: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      id: props.object._id,
      type: props.object.type,
      question: props.object.name,
      opinion: "",
      sequence: props.object.sequence,
    },
    onSubmit: (values) => handleClickNext(values),
    validationSchema: singleTypeSchema,
  });

  const handleClickSkip = (e) => {
    e.preventDefault();
    formik.resetForm();
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="paddingApp">
          <ul className="QueFlex">
            <li>
              <h6 className="globalText">
                {/* eslint-disable */}
                {page === 2 || page === 3 ? (
                  <>{`${
                    props.object.name?.[t("language")]
                      ? props.object.name?.[t("language")].replace(
                          /([\{(])(.+?)([\})])/g,
                          `${ratingName}`
                        )
                      : props.object.name?.[t("en")].replace(
                          /([\{(])(.+?)([\})])/g,
                          `${ratingName}`
                        )
                  }`}</>
                ) : (
                  <>
                    {props.object.name?.[t("language")]
                      ? props.object.name?.[t("language")]
                      : props.object.name?.[t("en")]}
                  </>
                )}
              </h6>
              <div className="paddingTextarea">
                <Form.Control
                  as="textarea"
                  rows={8}
                  name="opinion"
                  value={formik.values.opinion}
                  placeholder={t("SHARE_YOUR_OPINION_HERE")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </li>
          </ul>
          <div className="Nestbtn">
            <ButtonComponent
              buttonDisabled={!(formik.isValid && formik.dirty)}
              buttonType={"submit"}
              buttonLabel={[t("NEXT")]}
              clickFunction={() => handleClickNext(formik.values)}
            />
          </div>
          {props.object?.optional === false ? null : (
            <span
              style={{
                color: `${
                  getQuestion?.brand_design?.button_colour
                    ? getQuestion?.brand_design?.button_colour
                    : "#000000"
                }`,
                textDecoration: "none",
                cursor: "pointer",
              }}
              className="skipBtn QueFlexBtn"
              onClick={(e) => handleClickSkip(e)}
            >
              {t("SKIP")}
            </span>
          )}
        </div>
      </form>
    </>
  );
}

export default SingleTypeQuestion;
