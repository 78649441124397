import React from "react";
import { useTranslation } from "react-i18next";
import thankYou from "../../assets/images/thank-you.svg";

const ThankYou = () => {
  const { t } = useTranslation();
  return (
    <div className="redeemVoucher">
      <div className="code-border giftCode">
        <img
          loading="lazy"
          src={thankYou}
          alt="ThankYou"
          style={{ width: "100px", marginBottom: "30px" }}
        />
        <h6 className="golbalText" style={{ fontSize: "30px" }}>
          {[t("THANK_YOU")]}
        </h6>
      </div>
    </div>
  );
};

export default ThankYou;
