import { useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import NavBar from "./../../../components/landing/NavBar";
import Footer from "./../../../components/landing/Footer";
import ScrollingFeatureText from "./../../../components/landing/ScrollingFeatureText";
import CWCaseStudyImage from "./../../../assets/images/landing/cwcasestudy.svg";
import HeroArrow from "./../../../assets/images/landing/heroarrow.svg";
import DemoArrow from "./../../../assets/images/landing/demoarrow.svg";
import DemoQR from "./../../../assets/images/landing/demoqr.svg";

import "./DemoPage.css";

const DemoPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-[#FFF4EA]">
      <NavBar></NavBar>
      <section id="demo">
        <div className=" w-full h-full desktop:!pt-48 desktop:!pb-24 desktop:!px-48 mobile:!px-5 mobile:!pt-28 relative">
          <div className="flex gap-10 relative">
            <h1 className="font-bold desktop:text-6xl mobile:text-3xl font-SpaceGrotesk">
              {t("Scan Me")}
            </h1>
            <img
              className="absolute desktop:left-72 desktop:top-6 mobile:right-36 mobile:top-6 desktop:w-36 mobile:w-14"
              src={DemoArrow}
            ></img>
          </div>
          <div className="text-center">
            <img
              className="!mt-24 mx-auto mobile:hidden desktop:block"
              src={DemoQR}
            ></img>
            <div className="!mt-20 mobile:block desktop:hidden">
              <a
                href="#"
                className="no-underline rounded-2xl text-base !px-20 !py-5 font-SpaceGrotesk font-medium text-white bg-[#A0550A] hover:bg-[#742900]"
              >
                {t("Start The Survey")}
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default DemoPage;
