const BASE_URL = "/";
const ROUTE_URL = {
  LANDING: `${BASE_URL}`,
  LANDING_HOME: `${BASE_URL}home`,
  LANDING_DEMO: `${BASE_URL}demo`,
  LANDING_BLOG: `${BASE_URL}blog`,
  LANDING_GET_STARTED: `${BASE_URL}get-started`,
  LANDING_BLOGPOST_VIEW: `${BASE_URL}blog/:blogPostId`,
  HOME: `${BASE_URL}:brand/:code`,
  HOME_REWARD: `${BASE_URL}:brand/:code/reward`,
  REWARD_MENU: `${BASE_URL}:brand/:code/productlist`,
  SERVICE_LIST: `${BASE_URL}:brand/:code/servicelist`,
  MAIN_ROUTE: `${BASE_URL}:brand/:code/:page?/:question_id`,
  SPECIFIC_RATING: `${BASE_URL}:brand/:code/:page/:question_id/:type/:id/:name`,
  USER_INFO: `${BASE_URL}:brand/:code/info`,
  SOCIA_MEDIA: `${BASE_URL}:brand/:code/thankyou`,
  REDEEM: `${BASE_URL}brands/feedbacks/redeem/:code`,
  SUCCESS: `${BASE_URL}brands/feedbacks/redeem/success`,
  THANK_YOU: `${BASE_URL}brands/feedbacks/thankyou`,
};
export default ROUTE_URL;
