import React, { useState } from "react";
import search from "../../assets/images/search-normal.svg";
import { Tab, Tabs } from "react-bootstrap";
import Footer from "../../components/Footer";
import BackArrow from "../../components/BackArrow";
import AllImages from "../../components/AllImages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getQuestiostypeApi, searchProduct } from "../../middlewares/questions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Menu = () => {
  const { t } = useTranslation();
  const [isAvailable, setIsAvailable] = useState(0);
  const { getProductDetails, getQuestion } = useSelector(
    (state) => state.question
  );
  const dispatch = useDispatch();
  const { code, brand } = useParams();
  const brand_color = localStorage.getItem("button-color");

  useEffect(() => {
    dispatch(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getProductDetails && getProductDetails.length > 0) {
      const isAvailable = getProductDetails.findIndex((s) => s.products.length);
      setIsAvailable(isAvailable);
    }
  }, [getProductDetails]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChange = debounce(function (e) {
    const brand_id = getQuestion.brand_id;
    const payload = e.target.value;
    const branch_id = getQuestion?.branch_id;
    dispatch(searchProduct(branch_id, brand_id, payload));
  }, 1000);

  return (
    <div className="PCresponse">
      <BackArrow data={getQuestion} />
      <div className="paddingApp">
        <div className="InputSearch">
          <form className="searchIcon">
            <img src={search} loading="lazy" alt="" />
            <input
              className="InputBox"
              placeholder={t("SEARCH_NAME")}
              aria-describedby="basic-addon1"
              name="searchInput"
              onChange={(e) => handleChange(e)}
            />
          </form>
        </div>
        <div className="ProductTabs">
          <Tabs
            defaultActiveKey={`${isAvailable}`}
            className="mb-3"
            activeKey={`${isAvailable}`}
            onSelect={(e) => setIsAvailable(e)}
          >
            {getProductDetails &&
              getProductDetails.length > 0 &&
              getProductDetails.map((o, i) => {
                return (
                  <Tab
                    key={i}
                    eventKey={`${i}`}
                    title={
                      o.title?.[t("language")]
                        ? o.title?.[t("language")]
                        : o.title?.[t("en")]
                    }
                    tabAttrs={{
                      style: {
                        borderColor: ` ${
                          brand_color ? brand_color : "#ed1c24"
                        } `,
                      },
                    }}
                  >
                    <ul>
                      <li key={i}>
                        <div className="sandos-box">
                          {o.products &&
                            o.products.length > 0 &&
                            o.products.map((j, index) => {
                              return (
                                <>
                                  <div className="sandos-flex" key={index}>
                                    {j?.name?.en !== "Something Else" && (
                                      <>
                                        <AllImages logo={j.logo} id={j._id} />
                                      </>
                                    )}
                                    {j?.name?.en !== "Something Else" && (
                                      <div className="Text_box">
                                        <div className="sandosBox">
                                          <p>
                                            {j.name?.[t("language")]
                                              ? j.name?.[t("language")]
                                              : j.name?.[t("en")]}
                                          </p>
                                          <p
                                            className="bigFont"
                                            style={{ color: "#909090" }}
                                          >
                                            {j.description?.[t("language")]
                                              ? j.description?.[t("language")]
                                              : j.description?.[t("en")]}
                                          </p>
                                        </div>
                                        <h4>
                                          {" "}
                                          {j.price +
                                            " " +
                                            getQuestion?.brand_design?.currency}
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </li>
                    </ul>
                  </Tab>
                );
              })}
          </Tabs>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Menu;
