import { createSlice, current } from "@reduxjs/toolkit";

export const questionSlice = createSlice({
  name: "question",
  initialState: {
    getQuestion: [],
    getQuestionloading: true,
    getQuestionerror: null,
    getQuestionLang: null,
    getAllQuestions: [],
    getStaffDetails: null,
    getProductDetails: null,
    getServiceDetails: null,
    ratingOption: null,
    //post feedback
    addfeedback: [],
    addfeedbackloading: false,
    addfeedbackerror: null,

    message: "",

    answers: [],

    rewardStatus: null,
    rewardStatusloading: true,
    rewardStatuserror: null,
    rewardStatusMessage: "",
    rewardCheckAvailability: "",

    rewardLoading: false,
    rewardError: null,
    rewardMessage: "",

    getSearchProduct: [],
    productMessage: null,
    productError: null,
    productLoading: false,

    getSearchService: [],
    serviceMessage: null,
    serviceError: null,
    serviceLoading: false,

    designLanguage: [],
    languageError: null,
    languageLoading: false,
  },
  reducers: {
    getQuestionRequest: (state) => {
      state.getQuestion = [];
      state.getQuestionloading = true;
      state.getQuestionerror = null;
    },
    getQuestionSuccess: (state, action) => {
      state.ratingOption = action.payload.data.rating_options;
      state.getQuestion = action.payload.data;
      state.getQuestionLang = action.payload.lang;
      state.getAllQuestions = action.payload.data.question;
      state.getStaffDetails = action.payload.data.staff_members;
      state.getProductDetails = action.payload.data.product_category;
      state.getServiceDetails = action.payload.data.service_category;
      state.getQuestionloading = false;
      state.getQuestionerror = null;
    },
    getQuestionError: (state, action) => {
      state.getQuestionloading = false;
      state.getQuestionerror = action.payload;
    },
    addFeedbackRequest: (state) => {
      state.addfeedback = [];
      state.addfeedbackloading = true;
      state.addfeedbackerror = null;
    },
    addFeedbackSuccess: (state, action) => {
      state.addfeedback = action.payload.data;
      state.message = action.payload.data.message;
      state.addfeedbackloading = false;
      state.addfeedbackerror = null;
    },
    addFeedbackError: (state, action) => {
      state.addfeedback = [];
      state.addfeedbackloading = false;
      state.addfeedbackerror = action.payload;
    },
    addAnswers: (state, action) => {
      let data = current(state.answers);
      let ind = data && data.findIndex((o, i) => o?.id === action.payload?.id);
      if (ind > -1) {
        let objdata = { ...action.payload };
        let ss = [...data];
        ss.splice(ind, 1, objdata);
        data = ss;
      } else {
        data = [...data, action.payload];
      }
      state.answers = [...data];
    },
    checkRewardStatusRequest: (state) => {
      state.rewardStatus = [];
      state.rewardStatusloading = true;
      state.rewardStatuserror = null;
    },
    checkRewardStatusSuccess: (state, action) => {
      state.rewardStatus = action.payload.data;
      state.rewardCheckAvailability = action.payload.status;
      state.rewardStatusMessage = action.payload.message;
      state.rewardStatusloading = false;
      state.rewardStatuserror = null;
    },
    checkRewardStatusError: (state, action) => {
      state.rewardStatus = [];
      state.rewardStatusloading = false;
      state.rewardStatuserror = action.payload;
    },
    redeemRewardRequest: (state) => {
      state.rewardLoading = true;
      state.rewardError = null;
      state.rewardMessage = "";
    },
    redeemRewardSuccess: (state, action) => {
      state.rewardMessage = action.payload;
      state.rewardLoading = false;
      state.rewardError = null;
    },
    redeemRewardError: (state, action) => {
      state.rewardLoading = false;
      state.rewardError = action.payload;
    },
    designLanguageRequest: (state) => {
      state.languageLoading = true;
      state.designLanguage = [];
      state.languageError = null;
    },
    designLanguageSuccess: (state, action) => {
      state.designLanguage = action.payload;
      state.languageLoading = false;
      state.languageError = null;
    },
    designLanguageError: (state, action) => {
      state.languageLoading = false;
      state.languageError = action.payload;
    },
    productSearchRequest: (state) => {
      state.productLoading = true;
      state.productError = null;
      state.productMessage = "";
    },

    productSearchSuccess: (state, action) => {
      const data = current(state.getProductDetails);
      state.productLoading = false;
      state.productError = null;
      state.getSearchProduct = action.payload;

      state.getProductDetails = data.map((o, i) => {
        let catalogueobj = { ...o };
        const objectIndex = action.payload.findIndex(
          (obj) => obj._id === o._id
        );
        if (objectIndex > -1) {
          catalogueobj.products = action.payload[objectIndex].products;
        }
        if (objectIndex === -1) {
          catalogueobj.products = [];
        }
        return catalogueobj;
      });
    },
    productSearchError: (state, action) => {
      state.productLoading = false;
      state.productError = action.payload;
    },
    serviceSearchRequest: (state) => {
      state.serviceLoading = true;
      state.serviceError = null;
      state.serviceMessage = "";
    },
    serviceSearchSuccess: (state, action) => {
      const data = current(state.getServiceDetails);
      state.serviceLoading = false;
      state.serviceError = null;
      state.getSearchService = action.payload;

      state.getServiceDetails = data.map((o, i) => {
        let catalogueobj = { ...o };
        const objectIndex = action.payload.findIndex(
          (obj) => obj._id === o._id
        );
        if (objectIndex > -1) {
          catalogueobj.services = action.payload[objectIndex].services;
        }
        if (objectIndex === -1) {
          catalogueobj.services = [];
        }
        return catalogueobj;
      });
    },
    serviceSearchError: (state, action) => {
      state.serviceLoading = false;
      state.serviceError = action.payload;
    },
    clearAnswers: (state) => {
      state.answers = [];
    },
  },
});

export const {
  getQuestionRequest,
  getQuestionSuccess,
  getQuestionError,
  addFeedbackRequest,
  addFeedbackSuccess,
  addFeedbackError,
  addAnswers,
  checkRewardStatusRequest,
  checkRewardStatusSuccess,
  checkRewardStatusError,
  redeemRewardRequest,
  redeemRewardSuccess,
  redeemRewardError,
  productSearchError,
  productSearchRequest,
  productSearchSuccess,
  serviceSearchError,
  serviceSearchSuccess,
  serviceSearchRequest,
  designLanguageRequest,
  designLanguageSuccess,
  designLanguageError,
  clearAnswers,
} = questionSlice.actions;

export default questionSlice.reducer;
