import { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "./../../../assets/images/landing/logo.svg";
import LinkedIn from "./../../../assets/images/landing/linkedin.svg";
import Facebook from "./../../../assets/images/landing/facebook.svg";
import Twitter from "./../../../assets/images/landing/twitter.svg";
import TrustreeW from "./../../../assets/images/landing/trustreeW.svg";
import SendIcon from "./../../../assets/images/landing/send.svg";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer className="bg-[#181818] !py-20">
      <div className=" flex desktop:flex-row mobile:flex-col-reverse items-center mobile:gap-12 desktop:!px-28 !py-12">
        <div className=" grow desktop:w-2/5">
          <div className="flex flex-col desktop:text-start mobile:text-center mobile:items-center w-[325px]">
            <img className="w-24" src={TrustreeW}></img>
            <span className="!mt-4 no-underline text-[#FFFFFF] font-SpaceGrotesk font-normal text-base">
              {t("Copyright © 2023 Trustree ltd.")}
            </span>
            <span className="!mt-2 no-underline text-[#FFFFFF] font-SpaceGrotesk font-normal text-base">
              {t("All rights reserved")}
            </span>
            <div>
              <div className="!mt-14 flex justify-start gap-4">
                {/* <div>
                  <a className="text-white" href="#">
                    <img src={LinkedIn}></img>
                  </a>
                </div>
                <div>
                  <a className="text-white" href="#">
                    <img src={Facebook}></img>
                  </a>
                </div> */}
                <div>
                  <a className="text-white" href="https://www.youtube.com/@TrustreebyInframodern">
                    <img src={Twitter}></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="desktop:flex-none desktop:w-3/5 mobile:w-full mobile:!px-6">
          <div className="mobile:text-center flex desktop:flex-row mobile:flex-col-reverse desktop:items-start mobile:items-center">
            <div className="grow desktop:w-1/3 mobile:w-full !py-4">
              <div className="flex flex-row items-center justify-center gap-8">
                {/* <div>
                  <h5 className="text-[#FFFFFF] font-SpaceGrotesk font-medium text-2xl !mb-6 ">
                    {t("Company")}
                  </h5>
                  <ul className="nav flex-column">
                    <li className="nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("About us")}
                      </NavHashLink>
                    </li>
                    <li className="nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Contact us")}
                      </NavHashLink>
                    </li>
                    <li className="nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Pricing")}
                      </NavHashLink>
                    </li>
                    <li className="nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Testimonials")}
                      </NavHashLink>
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className=" text-[#FFFFFF] font-SpaceGrotesk font-medium text-2xl !mb-6">
                    {t("Support")}
                  </h5>
                  <ul className=" nav flex-column">
                    <li className=" nav-item !mb-2">
                      <a
                        href="/home#"
                        className=" text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Help center")}
                      </a>
                    </li>
                    <li className=" nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Terms of service")}
                      </NavHashLink>
                    </li>
                    <li className=" nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Legal")}
                      </NavHashLink>
                    </li>
                    <li className=" nav-item !mb-2">
                      <NavHashLink
                        smooth
                        to="/home#"
                        className="text-[#FFFFFF] font-SpaceGrotesk font-normal text-sm no-underline"
                      >
                        {t("Privacy policy")}
                      </NavHashLink>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="grow desktop:w-1/3 mobile:w-full !py-4">
              <h5 className=" text-[#FFFFFF] font-SpaceGrotesk font-medium text-2xl !mb-6">
                {t("Stay up to date")}
              </h5>
              <div class="relative">
                <input
                  type="email"
                  className="block w-full !p-3 font-normal text-sm text-[#D9DBE1] rounded-lg bg-[#464646]"
                  placeholder={t("Your email address")}
                  required
                />
                <button type="submit" className="absolute end-2.5 bottom-2.5">
                  <img className="" src={SendIcon}></img>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
