import { useTranslation } from "react-i18next";

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      dir={
        i18n.resolvedLanguage === "ar" ||
        i18n.resolvedLanguage === "he" ||
        i18n.resolvedLanguage === "ur" ||
        i18n.resolvedLanguage === "fa"
          ? "rtl"
          : "ltr"
      }
    >
      <div>{children}</div>
    </div>
  );
};

export default Layout;
