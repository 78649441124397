import MainContextDataReducerActionTypes from "./MainContextDataTypes";

export const MainContextDataReducerInitialState = {
  isCalendlyOpen: false,
  plansMonthlyAnnuallyToggle: false,
};

const MainContextDataReducer = (state, { type, payload }) => {
  switch (type) {
    case MainContextDataReducerActionTypes.IS_CALENDLY_OPEN: {
      return {
        ...state,
        isCalendlyOpen: payload,
      };
    }
    case MainContextDataReducerActionTypes.PLANS_MONTHLY_ANNUALLY_TOGGLE: {
      return {
        ...state,
        plansMonthlyAnnuallyToggle: payload,
      };
    }
    default:
      return state;
  }
};

export default MainContextDataReducer;
