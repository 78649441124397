import React from "react";
const BrandLogo = (props) => {
  return (
    <div className="LogoImg">
      {props.object?.brand_design?.show_logo === true ? (
        <>
          <img
            src={
              `${process.env.REACT_APP_BASE_URL}${props?.object?.brand_detail?.logo}` ||
              require("../assets/images/BrandLogo.png")
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://api.app.trustree.com/images/Brandlogo.png";
            }}
            alt="logo"
            id={props.id}
            loading="lazy"
          />
        </>
      ) : null}
    </div>
  );
};

export default BrandLogo;
