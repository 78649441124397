import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IndexedDBServices from "../services/indexedDB-services";
import { addAnswers } from "../slices/slice";

const MultipleTypeQuestion = (props) => {
  const { answers, getQuestion, getAllQuestions } = useSelector(
    (state) => state.question
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const params = useParams();
  const page = parseInt(params.page);

  useEffect(() => {
    IndexedDBServices.checkDBdata(params, nav);
    if (params.question_id !== props.object._id) {
      window.history.back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const questionIndex = answers?.findIndex(
      (o) => o?.id === getAllQuestions[page - 2]?._id
    );

    IndexedDBServices.addFeedbackAnswers(
      answers[
        questionIndex && questionIndex > 0 ? questionIndex : answers?.length - 1
      ]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const handleClick = (data) => {
    const MultipleAnswer = {
      id: props.object._id,
      question_id: props.object._id,
      question: props.object.name,
      question_type: props.object.type,
      sequence: props.object.sequence,
      answer: {
        selected_option: data,
      },
    };
    dispatch(addAnswers(MultipleAnswer));

    let questionLength = props.data.length;
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };

  const handleClickSkip = () => {
    let questionLength = props.data.length;
    if (questionLength === page) {
      nav(`/${params.brand}/${params.code}/info`);
    } else {
      nav(
        `/${params.brand}/${params.code}/${page + 1}/${
          getAllQuestions[page]?._id
        }`
      );
    }
  };

  return (
    <>
      <div className="paddingApp">
        <h6 className="globalText">
          {props.object.name?.[t("language")]
            ? props.object.name?.[t("language")]
            : props.object.name?.[t("en")]}
        </h6>
        <div className="SocialSection">
          <div className="multiButton">
            {props.object.options
              .filter((o) => o !== {})
              .map((data, i) => {
                return (
                  <div key={i} style={{ margin: "5px 5px 0 0" }}>
                    <Button
                      onClick={() => handleClick(data)}
                      className="btn btn-outline-primary"
                    >
                      {data[t("language")]
                        ? data[t("language")]
                        : data[t("en")]
                        ? data[t("en")]
                        : null}
                    </Button>
                  </div>
                );
              })}
            {props.object.optional === false ? null : (
              <p
                style={{
                  color: `${
                    getQuestion?.brand_design?.button_colour
                      ? getQuestion?.brand_design?.button_colour
                      : "#000000"
                  }`,
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                className="skipBtn"
                onClick={handleClickSkip}
              >
                {t("SKIP")}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleTypeQuestion;
