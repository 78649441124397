import React, { useEffect } from "react";
import ProgressiveBar from "../components/ProgressiveBar";
import { Outlet, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getQuestiostypeApi } from "../middlewares/questions";
import reward from "../assets/images/reward.svg";
import noreward from "../assets/images/noreward.svg";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../components/ButtonComponent";
import { Spinner } from "react-bootstrap";
import IndexedDBServices from "../services/indexedDB-services";
import { STORE_NAME, STORE_VERSION, CATEGORY_RATING } from "../helper/constant";

const HomeReward = () => {
  const { t } = useTranslation();
  const {
    getQuestion,
    getAllQuestions,
    getQuestionloading,
    getProductDetails,
    getServiceDetails,
    getQuestionerror,
  } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { code, brand } = useParams();
  const buttonColor = localStorage.getItem("button-color");

  useEffect(() => {
    if (brand && code) {
      dispatch(getQuestiostypeApi(brand, code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, code]);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(CATEGORY_RATING)) {
        IndexedDBServices.DeleteDB();
        window.location.reload();
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToNext = () => {
    nav(
      `/${getQuestion.brand_name}/${getQuestion.code}/${1}/${
        getAllQuestions[0]?._id
      }`
    );
  };

  const handleNavigateToMenu = () => {
    nav(`/${getQuestion.brand_name}/${getQuestion.code}/productlist`);
  };

  const handleNavigateToList = () => {
    nav(`/${getQuestion.brand_name}/${getQuestion.code}/servicelist`);
  };

  if (getQuestionerror) {
    return (
      <div className="PCresponse">
        <div className="paddingApp">
          <div className="surveyBtn">
            <div className="NavLogo">
              {getQuestionerror.logo ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${getQuestionerror.logo}`}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://api.app.trustree.com/images/Brandlogo.png";
                  }}
                  alt="logo"
                  loading="lazy"
                />
              ) : null}
            </div>
            {t(getQuestionerror.message)}
          </div>
        </div>
        <Footer title={"home"} questionData={getQuestion} />
      </div>
    );
  }

  return (
    <div className="PCresponse">
      {getQuestionloading ? (
        <Spinner
          style={{
            color: `${buttonColor ? buttonColor : "#164665"}`,
          }}
          animation="border"
        />
      ) : (
        <>
          <div className="TopBorder">
            <ProgressiveBar />
          </div>
          <div className="my-3">
            <div className="NavLogo">
              {getQuestion?.brand_design?.show_logo ? (
                <img
                  src={
                    `${process.env.REACT_APP_BASE_URL}${getQuestion?.brand_detail?.logo}` ||
                    require("../assets/images/BrandLogo.png")
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://api.app.trustree.com/images/Brandlogo.png";
                  }}
                  alt="logo"
                  loading="lazy"
                />
              ) : null}
            </div>
          </div>
          <div className="paddingApp">
            <div className="HomeReward">
              <div className="RewardSec">
                {getQuestion?.brand_design?.via_qr_or_survey_link_product ===
                  true &&
                getProductDetails &&
                getProductDetails.length > 0 ? (
                  <ButtonComponent
                    clickFunction={() => handleNavigateToMenu()}
                    buttonLabel={
                      getQuestion?.brand_design
                        ?.edit_button_text_products_list !== null
                        ? getQuestion?.brand_design
                            ?.edit_button_text_products_list?.[t("language")]
                          ? getQuestion?.brand_design
                              ?.edit_button_text_products_list?.[t("language")]
                          : [t("MENU")]
                        : null
                    }
                  />
                ) : (
                  <></>
                )}
                {getQuestion?.brand_design?.via_qr_or_survey_link_service ===
                  true &&
                getServiceDetails &&
                getServiceDetails.length > 0 ? (
                  <ButtonComponent
                    clickFunction={() => handleNavigateToList()}
                    buttonLabel={
                      getQuestion?.brand_design
                        ?.edit_button_text_services_list !== null
                        ? getQuestion?.brand_design
                            ?.edit_button_text_services_list?.[t("language")]
                          ? getQuestion?.brand_design
                              ?.edit_button_text_services_list?.[t("language")]
                          : [t("SERVICE_LIST")]
                        : null
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              <div
                className="borderDashed"
                style={{
                  border: `2px dashed${buttonColor ? buttonColor : "#164665"}`,
                }}
              >
                <h6 className="globalText">{t("GET_BETTER")}</h6>
                <div className="SurvetBox">
                  <div>
                    <img
                      src={
                        getQuestion?.rewards?.is_reward_on === true
                          ? reward
                          : noreward
                      }
                      alt="reward"
                      loading="lazy"
                    />
                    <p>
                      {getQuestion?.rewards?.is_reward_on === true
                        ? getQuestion?.rewards?.title?.[t("language")]
                          ? getQuestion?.rewards?.title?.[t("language")]
                          : getQuestion?.rewards?.title?.[t("en")]
                        : null}
                    </p>
                  </div>
                </div>
                <ButtonComponent
                  buttonType={"button"}
                  clickFunction={() => handleNavigateToNext()}
                  buttonLabel={
                    getQuestion?.brand_design
                      ?.edit_button_text_survey_button !== null
                      ? getQuestion?.brand_design
                          ?.edit_button_text_survey_button?.[t("language")]
                        ? getQuestion?.brand_design
                            ?.edit_button_text_survey_button?.[t("language")]
                        : [t("START_SURVEY")]
                      : null
                  }
                />
              </div>
              <ul>
                <li>
                  <strong>
                    {getQuestion?.rewards?.is_reward_on === true
                      ? t("TERMS")
                      : null}
                  </strong>
                </li>
                <li>
                  <div>
                    {getQuestion?.rewards?.is_reward_on === true
                      ? getQuestion.rewards?.terms_and_condition.map((o, i) => {
                          return (
                            <div key={i}>
                              <p>
                                {o?.[t("language")]
                                  ? o?.[t("language")]
                                  : o?.[t("en")]}
                              </p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <Footer />
          <Outlet />
        </>
      )}
    </div>
  );
};
export default HomeReward;
