import { configureStore } from "@reduxjs/toolkit";
import questionSlice from "../slices/slice";

function configStore() {
  return configureStore({
    reducer: {
      question: questionSlice,
    },
  });
}

export default configStore;
