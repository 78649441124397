import axios from "axios";
import i18next from "i18next";
const API_URL = `${process.env.REACT_APP_BASE_URL}`;
const URL = "/brands/feedbacks";

const axiosAPI = axios.create({ baseURL: API_URL });

axiosAPI.interceptors.request.use(
  (config) => {
    const i18Langugae = localStorage.getItem("i18nextLng");
    if (i18Langugae) {
      config.headers["ttxlanguage"] = i18Langugae;
      config.headers["ttxTimeZone"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const getQuestions = (brand, code, config = {}) => {
  return axiosAPI.get(`${URL}/${brand}/${code}`, { ...config });
};

const addFeedback = (data, config = {}) => {
  return axiosAPI.post(`${URL}/save-feedback`, { ...data }, { ...config });
};

const checkRewardStatus = (code, config = {}) => {
  return axiosAPI.get(`${URL}/check-reward-status/${code}`, { ...config });
};

const redeemReward = (code, is_redeem, config = {}) => {
  return axiosAPI.get(`${URL}/redeem-reward/${code}/${is_redeem}`, {
    ...config,
  });
};
const productSearch = (branch_id, b_id, searchKey, config = {}) => {
  return axiosAPI.get(
    `${URL}/search-products/${b_id}/${branch_id}?search=${searchKey}`,
    {
      ...config,
    }
  );
};
const serviceSearch = (branch_id, b_id, searchKey, config = {}) => {
  return axiosAPI.get(
    `${URL}/search-services/${b_id}/${branch_id}?search=${searchKey}`,
    {
      ...config,
    }
  );
};
const getLanguages = (config = {}) => {
  return axiosAPI.get("/brands/get-languages", { ...config });
};
const questionsAPIs = {
  getQuestions,
  addFeedback,
  checkRewardStatus,
  redeemReward,
  productSearch,
  serviceSearch,
  getLanguages,
};

export default questionsAPIs;
