import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Form, FormCheck, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { getQuestiostypeApi } from "../middlewares/questions";
import * as Yup from "yup";
import IndexedDBServices from "../services/indexedDB-services";
import { addAnswers } from "../slices/slice";
import { useTranslation } from "react-i18next";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ButtonComponent from "../components/ButtonComponent";
import {
  OBJECT_STORE_ANSWERS,
  STORE_NAME,
  STORE_VERSION,
} from "../helper/constant";

const Information = () => {
  const { t } = useTranslation();
  const { getQuestion, answers, getQuestionloading } = useSelector(
    (state) => state.question
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { code, brand } = useParams();
  const [dialCode, setDialCode] = useState();
  const [dialCountry, setDialCountry] = useState();
  const [buttonName, setButtonName] = useState();
  const telRef = useRef();
  const buttonColor = localStorage.getItem("button-color");

  useEffect(() => {
    dispatch(getQuestiostypeApi(brand, code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IndexedDBServices.checkIndexedDB();
    const dbPromise = indexedDB.open(STORE_NAME, STORE_VERSION);
    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_ANSWERS)) {
        nav(`/${brand}/${code}/reward`);
      } else {
        const tx = db.transaction(OBJECT_STORE_ANSWERS, "readwrite");
        const storeData = tx.objectStore(OBJECT_STORE_ANSWERS);
        const cat = storeData.getAll();
        cat.onsuccess = (query) => {
          let DBdata = query.srcElement.result;
          if (DBdata.length < 1) {
            nav("/");
          }
        };
      }
      db.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    IndexedDBServices.addFeedbackAnswers(answers[answers.length - 1]);
    if (getQuestion?.rewards && getQuestion?.rewards.length > 0) {
      setButtonName([t("CLAIM_YOUR_GIFT")]);
    } else {
      setButtonName([t("SUBMIT")]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestion]);

  const handleClickNext = (values) => {
    const value = {
      id: getQuestion._id,
      branch_id: getQuestion.branch_id,
      brand_id: getQuestion.brand_id,
      experience_type_id: getQuestion.experience_type_id,
      survey_id: getQuestion.survey_id,
      customer_name: values.customer_name,
      customer_phone: "+" + dialCode + "-" + values.customer_phone,
      customer_email: values.customer_email,
      share_customer_data: values.share_customer_data,
      // terms: values.terms,
    };
    dispatch(addAnswers(value));
    nav(`/${brand}/${code}/thankyou`);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      id: getQuestion._id,
      branch_id: getQuestion.branch_id,
      brand_id: getQuestion.brand_id,
      experience_type_id: getQuestion.experience_type_id,
      survey_id: getQuestion.survey_id,
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      share_customer_data: true,
      // terms: false,
    },
    validationSchema: Yup.object({
      customer_name: Yup.string()
        .required("Must input this field")
        .max(40)
        .min(2),
      customer_phone: Yup.string()
        .test("valid-phone", "Phone format not correct", (value) => {
          return telRef?.current.isValidNumber(telRef.current.state.value);
        })
        .required("Please input valid number"),
      customer_email: Yup.string()
        .required("Must input this field")
        .email("Invalid Email Address"),
      // terms: Yup.boolean()
      //   .oneOf([true], "Please accept the terms and conditions")
      //   .required("Please accept the terms and conditions"),
    }),
    onSubmit: (values) => {
      handleClickNext(values);
    },
  });

  const ipLoc = async () => {
    const aa = await fetch("https://ipinfo.io/json?token=5fed51300cc547");
    const q = await aa.json();
    return setDialCountry(q.country);
  };
  useEffect(() => {
    ipLoc();
  }, []);

  return (
    <div className="PCresponse">
      {getQuestionloading ? (
        <Spinner
          style={{
            color: `${buttonColor ? buttonColor : "#164665"}`,
          }}
          animation="border"
        />
      ) : (
        <div className="paddingApp information">
          <div className="NavLogo">
            {getQuestion?.brand_design?.show_logo ? (
              <img
                src={
                  `${process.env.REACT_APP_BASE_URL}${getQuestion?.brand_detail?.logo}` ||
                  require("../assets/images/BrandLogo.png")
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://api.app.trustree.com/images/Brandlogo.png";
                }}
                alt="logo"
                loading="lazy"
              />
            ) : null}
          </div>

          <div className="info_Section">
            <div className="information_Wpr">
              {getQuestion.rewards && getQuestion.rewards.length > 0 ? (
                <div>
                  <h6 className="globalText">{t("YOUR_GIFT")}</h6>
                  <p>{t("GET_GIFT")}</p>
                </div>
              ) : (
                <h6 className="globalText">{t("COMPLETE")}</h6>
              )}
              <div className="InputSearch">
                <form
                  className="searchIcon infoPage px-3"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="w-100 mb-3 text-start">
                    <Form.Control
                      type="text"
                      name="customer_name"
                      value={formik.values.customer_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control ${
                        formik.touched.customer_name &&
                        formik.errors.customer_name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder={t("NAME")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    {formik.touched.customer_name &&
                      formik.errors.customer_name && (
                        <div className="invalid-feedback d-block">
                          {formik.errors.customer_name}
                        </div>
                      )}
                  </div>
                  <div className="w-100 mb-3 text-start ">
                    <IntlTelInput
                      defaultCountry={
                        dialCountry && dialCountry
                          ? dialCountry.toLowerCase()
                          : "eg"
                      }
                      containerClassName="intl-tel-input mb-0"
                      inputClassName={`form-control ${
                        formik.touched.customer_phone &&
                        formik.errors.customer_phone
                          ? "is-invalid"
                          : ""
                      }`}
                      style={{ width: "100%", marginBottom: "15px" }}
                      ref={telRef}
                      onPhoneNumberChange={(
                        isValid,
                        number,
                        object,
                        dialCodeNumber
                      ) => {
                        setDialCode(object.dialCode);
                        formik.setFieldValue("customer_phone", number);
                        formik.setFieldTouched("customer_phone", true);
                      }}
                      onPhoneNumberBlur={(
                        isValid,
                        number,
                        object,
                        dialCodeNumber
                      ) => {
                        formik.setFieldValue("customer_phone", number);
                        formik.setFieldTouched("customer_phone", true);
                      }}
                      fieldName={"customer_phone"}
                      value={formik.values.customer_phone}
                      formatOnInit={false}
                      format={false}
                      useMobileFullscreenDropdown={false}
                      separateDialCode={false}
                    />
                    {formik.touched.customer_phone &&
                      formik.errors.customer_phone && (
                        <div className="invalid-feedback d-block">
                          {formik.errors.customer_phone}
                        </div>
                      )}
                  </div>
                  <div className="w-100 mb-3 text-start">
                    <Form.Control
                      type="email"
                      name="customer_email"
                      value={formik.values.customer_email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      aria-label="Example text with button addon"
                      className={`form-control ${
                        formik.touched.customer_email &&
                        formik.errors.customer_email
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder={t("EMAIL")}
                      aria-describedby="basic-addon1"
                    />
                    {formik.touched.customer_email &&
                      formik.errors.customer_email && (
                        <div className="invalid-feedback d-block">
                          {formik.errors.customer_email}
                        </div>
                      )}
                  </div>
                  <ul className="checkbox_Sec">
                    {getQuestion?.rating_options?.customer_data === "Ask" ? (
                      <li>
                        <FormCheck.Input
                          style={{
                            backgroundColor: formik.values.share_customer_data
                              ? `${
                                  getQuestion?.brand_design?.button_colour
                                    ? getQuestion?.brand_design?.button_colour
                                    : "#164665"
                                }`
                              : "#ffffff",
                            borderColor: formik.values.share_customer_data
                              ? `${
                                  getQuestion?.brand_design?.button_colour
                                    ? getQuestion?.brand_design?.button_colour
                                    : "#164665"
                                }`
                              : "#A9D0F6",
                          }}
                          type="checkbox"
                          value="share_customer_data"
                          name="share_customer_data"
                          onChange={formik.handleChange}
                          checked={formik.values.share_customer_data === true}
                        />
                        <label>{t("SHARE")}</label>
                      </li>
                    ) : (
                      <></>
                    )}
                    {/* <li>
                      <FormCheck.Input
                        style={{
                          backgroundColor: formik.values.terms
                            ? `${
                                getQuestion?.brand_design?.button_colour
                                  ? getQuestion?.brand_design?.button_colour
                                  : "#164665"
                              }`
                            : "#ffffff",
                          borderColor: formik.values.terms
                            ? `${
                                getQuestion?.brand_design?.button_colour
                                  ? getQuestion?.brand_design?.button_colour
                                  : "#164665"
                              }`
                            : "#A9D0F6",
                        }}
                        type="checkbox"
                        value="terms"
                        name="terms"
                        className={`${
                          formik.touched.terms && formik.errors.terms
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        checked={formik.values.terms === true}
                      />
                      {formik.touched.terms && formik.errors.terms && (
                        <div style={{ color: "red" }}>
                          {formik.errors.terms}
                        </div>
                      )}
                      <label>{t("ASK_TERMS")}</label>
                    </li> */}
                  </ul>
                  <div className="Nestbtn ">
                    <ButtonComponent
                      buttonDisabled={!(formik.isValid && formik.dirty)}
                      buttonType={"submit"}
                      buttonLabel={buttonName}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Information;
