import { useTranslation } from "react-i18next";
import LeaveCardLeave from "./../../../assets/images/landing/leavecardleave.svg";

const LeaveCard = ({ className, title, subtitle }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={
        "bg-white rounded-lg border-2 border-[#181818] desktop:w-[350px] desktop:h-[185px] mobile:h-auto !p-6 relative " +
        (className || "")
      }
    >
      <div className="font-SpaceGrotesk text-center font-semibold text-[#181818] text-2xl">
        {title}
      </div>
      <div className="text-[#181818] text-center font-SpaceGrotesk font-normal text-sm !mt-2">
        {subtitle}
      </div>
    </div>
  );
};

export default LeaveCard;
