import i18n from "i18next";
import translationEN from "../src/translation/en/translation.json";
import translationAR from "../src/translation/ar/translation.json";
/*import translationAF from "../src/translation/af/translation.json";
import translationAM from "../src/translation/am/translation.json";
import translationAZ from "../src/translation/az/translation.json";
import translationBE from "../src/translation/be/translation.json";
import translationBG from "../src/translation/bg/translation.json";
import translationBN from "../src/translation/bn/translation.json";
import translationBS from "../src/translation/bs/translation.json";
import translationCA from "../src/translation/ca/translation.json";
import translationCS from "../src/translation/cs/translation.json";
import translationCEB from "../src/translation/ceb/translation.json";
import translationCO from "../src/translation/co/translation.json";
import translationCY from "../src/translation/cy/translation.json";
import translationDA from "../src/translation/da/translation.json";
import translationDE from "../src/translation/de/translation.json";
import translationEL from "../src/translation/el/translation.json";
import translationEO from "../src/translation/eo/translation.json";
import translationES from "../src/translation/es/translation.json";
import translationET from "../src/translation/et/translation.json";
import translationEU from "../src/translation/eu/translation.json";
import translationFA from "../src/translation/fa/translation.json";
import translationFI from "../src/translation/fi/translation.json";
import translationFIL from "../src/translation/fil/translation.json";
import translationFR from "../src/translation/fr/translation.json";
import translationFY from "../src/translation/fy/translation.json";
import translationGA from "../src/translation/ga/translation.json";
import translationGD from "../src/translation/gd/translation.json";
import translationGL from "../src/translation/gl/translation.json";
import translationGU from "../src/translation/gu/translation.json";
import translationHA from "../src/translation/ha/translation.json";
import translationHAW from "../src/translation/haw/translation.json";
import translationHE from "../src/translation/he/translation.json";
import translationHI from "../src/translation/hi/translation.json";
import translationHMN from "../src/translation/hmn/translation.json";
import translationHR from "../src/translation/hr/translation.json";
import translationHT from "../src/translation/ht/translation.json";
import translationHU from "../src/translation/hu/translation.json";
import translationHY from "../src/translation/hy/translation.json";
import translationID from "../src/translation/id/translation.json";
import translationIG from "../src/translation/ig/translation.json";
import translationIS from "../src/translation/is/translation.json";
import translationIT from "../src/translation/it/translation.json";
import translationJA from "../src/translation/ja/translation.json";
import translationKA from "../src/translation/ka/translation.json";
import translationKK from "../src/translation/kk/translation.json";
import translationKM from "../src/translation/km/translation.json";
import translationKN from "../src/translation/kn/translation.json";
import translationKO from "../src/translation/ko/translation.json";
import translationKU from "../src/translation/ku/translation.json";
import translationKY from "../src/translation/ky/translation.json";
import translationLA from "../src/translation/la/translation.json";
import translationLB from "../src/translation/lb/translation.json";
import translationLO from "../src/translation/lo/translation.json";
import translationLT from "../src/translation/lt/translation.json";
import translationLV from "../src/translation/lv/translation.json";
import translationMG from "../src/translation/mg/translation.json";
import translationMI from "../src/translation/mi/translation.json";
import translationMK from "../src/translation/mk/translation.json";
import translationML from "../src/translation/ml/translation.json";
import translationMN from "../src/translation/mn/translation.json";
import translationMR from "../src/translation/mr/translation.json";
import translationMS from "../src/translation/ms/translation.json";
import translationMT from "../src/translation/mt/translation.json";
import translationMY from "../src/translation/my/translation.json";
import translationNE from "../src/translation/ne/translation.json";
import translationNL from "../src/translation/nl/translation.json";
import translationNO from "../src/translation/no/translation.json";
import translationNY from "../src/translation/ny/translation.json";
import translationOR from "../src/translation/or/translation.json";
import translationPA from "../src/translation/pa/translation.json";
import translationPL from "../src/translation/pl/translation.json";
import translationPS from "../src/translation/ps/translation.json";
import translationPT from "../src/translation/pt/translation.json";
import translationRO from "../src/translation/ro/translation.json";
import translationRU from "../src/translation/ru/translation.json";
import translationRW from "../src/translation/rw/translation.json";
import translationSD from "../src/translation/sd/translation.json";
import translationSI from "../src/translation/si/translation.json";
import translationSK from "../src/translation/sk/translation.json";
import translationSL from "../src/translation/sl/translation.json";
import translationSM from "../src/translation/sm/translation.json";
import translationSN from "../src/translation/sn/translation.json";
import translationSO from "../src/translation/so/translation.json";
import translationSQ from "../src/translation/sq/translation.json";
import translationSR from "../src/translation/sr/translation.json";
import translationST from "../src/translation/st/translation.json";
import translationSU from "../src/translation/su/translation.json";
import translationSV from "../src/translation/sv/translation.json";
import translationSW from "../src/translation/sw/translation.json";
import translationTA from "../src/translation/ta/translation.json";
import translationTE from "../src/translation/te/translation.json";
import translationTG from "../src/translation/tg/translation.json";
import translationTH from "../src/translation/th/translation.json";
import translationTK from "../src/translation/tk/translation.json";
import translationTL from "../src/translation/tl/translation.json";
import translationTR from "../src/translation/tr/translation.json";
import translationTT from "../src/translation/tt/translation.json";
import translationUG from "../src/translation/ug/translation.json";
import translationUK from "../src/translation/uk/translation.json";
import translationUR from "../src/translation/ur/translation.json";
import translationUZ from "../src/translation/uz/translation.json";
import translationVI from "../src/translation/vi/translation.json";
import translationXH from "../src/translation/xh/translation.json";
import translationYI from "../src/translation/yi/translation.json";
import translationYO from "../src/translation/yo/translation.json";
import translationZU from "../src/translation/zu/translation.json";*/
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { supportedLanguages } from "./helper/supportedLanguages";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  /*af: {
    translation: translationAF,
  },
  am: {
    translation: translationAM,
  },
  az: {
    translation: translationAZ,
  },
  be: {
    translation: translationBE,
  },
  bg: {
    translation: translationBG,
  },
  bn: {
    translation: translationBN,
  },
  bs: {
    translation: translationBS,
  },
  ca: {
    translation: translationCA,
  },
  ceb: {
    translation: translationCEB,
  },
  co: {
    translation: translationCO,
  },
  cs: {
    translation: translationCS,
  },
  cy: {
    translation: translationCY,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
  },
  el: {
    translation: translationEL,
  },
  eo: {
    translation: translationEO,
  },
  es: {
    translation: translationES,
  },
  et: {
    translation: translationET,
  },
  eu: {
    translation: translationEU,
  },
  fa: {
    translation: translationFA,
  },
  fr: {
    translation: translationFR,
  },
  fi: {
    translation: translationFI,
  },
  fil: {
    translation: translationFIL,
  },
  fy: {
    translation: translationFY,
  },
  ga: {
    translation: translationGA,
  },
  gd: {
    translation: translationGD,
  },
  gl: {
    translation: translationGL,
  },
  gu: {
    translation: translationGU,
  },
  ha: {
    translation: translationHA,
  },
  haw: {
    translation: translationHAW,
  },
  he: {
    translation: translationHE,
  },
  hi: {
    translation: translationHI,
  },
  hmn: {
    translation: translationHMN,
  },
  hr: {
    translation: translationHR,
  },
  ht: {
    translation: translationHT,
  },
  hu: {
    translation: translationHU,
  },
  hy: {
    translation: translationHY,
  },
  id: {
    translation: translationID,
  },
  ig: {
    translation: translationIG,
  },
  is: {
    translation: translationIS,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  ka: {
    translation: translationKA,
  },
  kk: {
    translation: translationKK,
  },
  km: {
    translation: translationKM,
  },
  kn: {
    translation: translationKN,
  },
  ko: {
    translation: translationKO,
  },
  ku: {
    translation: translationKU,
  },
  ky: {
    translation: translationKY,
  },
  la: {
    translation: translationLA,
  },
  lb: {
    translation: translationLB,
  },
  lo: {
    translation: translationLO,
  },
  lt: {
    translation: translationLT,
  },
  lv: {
    translation: translationLV,
  },
  mg: {
    translation: translationMG,
  },
  mi: {
    translation: translationMI,
  },
  mk: {
    translation: translationMK,
  },
  ml: {
    translation: translationML,
  },
  mn: {
    translation: translationMN,
  },
  mr: {
    translation: translationMR,
  },
  ms: {
    translation: translationMS,
  },
  mt: {
    translation: translationMT,
  },
  my: {
    translation: translationMY,
  },
  ne: {
    translation: translationNE,
  },
  nl: {
    translation: translationNL,
  },
  no: {
    translation: translationNO,
  },
  ny: {
    translation: translationNY,
  },
  or: {
    translation: translationOR,
  },
  pa: {
    translation: translationPA,
  },
  pl: {
    translation: translationPL,
  },
  ps: {
    translation: translationPS,
  },
  pt: {
    translation: translationPT,
  },
  ro: {
    translation: translationRO,
  },
  rw: {
    translation: translationRW,
  },
  ru: {
    translation: translationRU,
  },
  sd: {
    translation: translationSD,
  },
  si: {
    translation: translationSI,
  },
  sk: {
    translation: translationSK,
  },
  sl: {
    translation: translationSL,
  },
  sm: {
    translation: translationSM,
  },
  sn: {
    translation: translationSN,
  },
  so: {
    translation: translationSO,
  },
  sq: {
    translation: translationSQ,
  },
  sr: {
    translation: translationSR,
  },
  st: {
    translation: translationST,
  },
  su: {
    translation: translationSU,
  },
  sv: {
    translation: translationSV,
  },
  sw: {
    translation: translationSW,
  },
  ta: {
    translation: translationTA,
  },
  te: {
    translation: translationTE,
  },
  tg: {
    translation: translationTG,
  },
  th: {
    translation: translationTH,
  },
  tk: {
    translation: translationTK,
  },
  tl: {
    translation: translationTL,
  },
  tr: {
    translation: translationTR,
  },
  tt: {
    translation: translationTT,
  },
  ug: {
    translation: translationUG,
  },
  uk: {
    translation: translationUK,
  },
  ur: {
    translation: translationUR,
  },
  uz: {
    translation: translationUZ,
  },
  vi: {
    translation: translationVI,
  },
  xh: {
    translation: translationXH,
  },
  yi: {
    translation: translationYI,
  },
  yo: {
    translation: translationYO,
  },
  zu: {
    translation: translationZU,
  },*/
};

const i8 = () => {
  const supportLang =
    supportedLanguages &&
    supportedLanguages.map((o) => {
      // supportLang.push(o.key);
      return o.key;
    });
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: "en",
      load: "languageOnly",
      supportedLngs: supportLang ? supportLang : ["en", "ar"],
      // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      interpolation: {
        escapeValue: false,
      },
    });
};

export default i8;
