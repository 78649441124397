import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function ProgressiveBar() {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const { getAllQuestions, getQuestion } = useSelector(
    (state) => state.question
  );
  let questionLength = getAllQuestions.length;
  let paramas = useParams();
  let page = parseInt(paramas.page);
  const now = (page / questionLength) * 100;

  return (
    <ProgressBar
      dir={
        selectedLanguage === "ar" ||
        selectedLanguage === "fa" ||
        selectedLanguage === "he" ||
        selectedLanguage === "ur"
          ? "rtl"
          : ""
      }
      children={[
        <div
          key="1"
          role={"progressbar"}
          className="progress-bar"
          style={{
            backgroundColor: `${
              getQuestion?.brand_design?.button_colour
                ? getQuestion?.brand_design?.button_colour
                : "#dc3545"
            }`,
            width: `${now}%`,
          }}
        >
          <span hidden>{`${now}%`}</span>
        </div>,
      ]}
    />
  );
}

export default ProgressiveBar;
